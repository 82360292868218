import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import nodata from '../../Img/thankyou.gif';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import usdt from "../../Img/usdt.svg";
import { Link } from "react-router-dom";
import usercalls from "../../Axios";
import consts from '../../constant';
import Pagination from "@mui/material/Pagination";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast, ToastContainer } from 'react-toastify';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function MyAds() {

    const path = usercalls();

    const navigate = useNavigate()

    const [value, setValue] = useState(0);
    const [myP2p, setMyP2p] = useState([]);
    const [completedP2p, setCompletedP2p] = useState([]);
    const [cancledP2p, setCancledP2p] = useState([]);
    const [items, setItems] = useState(10);
    const [page, setPage] = useState(1);
    const [process, setProcess] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [value2, setValue2] = useState(0);

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const getMyP2p = async () => {

        try {

            var payload = {
                type: "user"
            };

            const url = `${consts.BackendUrl}/binanceTrade/getp2p`;

            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                setMyP2p(result?.result);
                setCompletedP2p(result?.result?.filter(item => item.status == "completed"));
                setCancledP2p(result?.result?.filter(item => item.status == "cancelled"));
            }

        } catch (error) {
            console.log(error, "error fetching p2p list");
        }

    };

    const getProcessP2p = async () => {

        try {

            const url = `${consts.BackendUrl}/p2p/processp2p`;

            const response = await path.getCallWithToken({ url });
            const result = await response.json();

            if (result?.success) {
                setProcess(result?.result);
            }

        } catch (error) {
            console.log(error, "error fetching p2p list");
        }

    };

    useEffect(() => {
        getMyP2p()
        getProcessP2p()
    }, []);

    const handleIconClick = (e, txId) => {
        // Prevent the click event from propagating to the Link
        e.stopPropagation();
        e.preventDefault();
    
        // Handle copy logic here
        navigator.clipboard.writeText(txId).then(() => {
        toast.success("Transaction ID copied");
        });
      };

    const paginatedP2p = myP2p.slice((page - 1) * items, page * items);
    const paginatedCompletedP2p = completedP2p.slice((page - 1) * items, page * items);
    const paginatedCancledP2p = cancledP2p.slice((page - 1) * items, page * items);

    return (
        <div className='Myads'>
             <ToastContainer />
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
            </Grid>
            <div className='myads-full'>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='sti_ck' >
                        <div class="ivon-back">
                            <Link to="/p2p" className="light-mode-img">
                                <img src={darkarrow} />
                            </Link>
                            <Link to="/p2p" className="dark-mode-img">
                                <img src={lightarrow} />
                            </Link>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <div class="ivon-back">
                            <Link to="/p2p" className="light-mode-img">
                                <img src={darkarrow} />
                            </Link>
                            <Link to="/p2p" className="dark-mode-img">
                                <img src={lightarrow} />
                            </Link>
                        </div> */}
                        <div className='post-ad-title text-center'>

                            My Ads
                        </div>

                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='ticket-box'>
                        <Box sx={{ padding: '10px 0px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Processing" {...a11yProps(0)} />
                                <Tab label="Order" {...a11yProps(1)} />

                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div>
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Type/coin </TableCell>
                                                <TableCell align='left'>Flat amount</TableCell>
                                                <TableCell align='left'>Price</TableCell>
                                                <TableCell align='left'>Crypto amount</TableCell>
                                                <TableCell align='left'>Status</TableCell>
                                                {/* <TableCell align='left'>Price</TableCell> */}
                                                <TableCell align='right'>Operation</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {console.log(process,"processprocess")} */}
                                            {process.length > 0 ? process.map((row, i) => (
                                                <React.Fragment key={i}>
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={10}>

                                                            <div class="myads-table-div d-flex">
                                                                <div class="Payment-Methods-main-card-two">

                                                                    <div class="dtime">{ row?.createdAt.split("T")[0] }</div>
                                                                    <div><span class="verify" flow="down"><i class="fa-solid fa-certificate"></i>
                                                                        Required Verification</span>
                                                                    </div>
                                                                </div>
                                                                <div class="Payment-Methods-main-card">
                                                                    <Link to="/orderdetails" state={ row?.txId }>
                                                                        <span>{row?.txId}</span>
                                                                        <ContentCopyIcon onClick={(e) => handleIconClick(e, row?.txId)} />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell >
                                                            <div class="td-flex" >   {/* <img src={usdt} alt="image" />*/}USDT </div> 
                                                        </TableCell>
                                                        <TableCell sx={{ p: 2 }}> <div class="tdfiat" > {row?.actualPrice}</div> </TableCell>
                                                        <TableCell>{row?.price} </TableCell>
                                                        <TableCell>{row?.quantity}</TableCell>


                                                        <TableCell>{row?.status}</TableCell>
                                                        {/* <TableCell></TableCell> */}
                                                        <TableCell align='right' >
                                                            <Link class="theme-clr" href="/support">Contact</Link>
                                                        </TableCell>
                                                    </TableRow>
                                                </ React.Fragment>
                                            )) :
                                             <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'> 
                                                        No Record Found
                                                    </div>
                                                </TableCell>
                                            </TableRow> 

                                            }
                                            {/* <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'> 
                                                        No Record Found
                                                    </div>


                                                </TableCell>
                                            </TableRow> */}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box className="completd-table">
                                <Tabs value={value2} onChange={handleChange2} aria-label="basic tabs example">
                                    <Tab label="All" {...a11yProps(0)} />
                                    <Tab label="Completed" {...a11yProps(1)} />
                                    <Tab label="Cancelled" {...a11yProps(2)} />

                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value2} index={0}>
                                <div>
                                    <TableContainer >
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Type/coin </TableCell>
                                                    <TableCell align='left'>Volume</TableCell>
                                                    <TableCell align='left'>Price</TableCell>
                                                    <TableCell align='left'>Minimum Limit</TableCell>
                                                    <TableCell align='left'>Status</TableCell>

                                                    <TableCell align='right'>Operation</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* <TableRow>
                                                    <TableCell align='center' colSpan={10}>
                                                        <div className='display-1'>
                                                            2024-05-16
                                                            <div className='green cursor' onClick={(() => { navigate('/orderdetails') })}>
                                                                TXNIAFH879A4D6A
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                </TableRow> */}
                                                {paginatedP2p.length > 0 ? paginatedP2p.map((item, index) => (
                                                    <TableRow className='iner-bg-row' key={index}>
                                                        <TableCell align='left'>
                                                            {/* USDT */}
                                                            {item?.type + " / " + item?.fromAsset}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {/* 98 */}
                                                            {item?.volume}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {/* 98 */}
                                                            {item?.price}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {/* 1 */}
                                                            {item?.minLimit}
                                                        </TableCell>
                                                        <TableCell align='left'>
                                                            {/* Cancelled */}
                                                            {item?.status}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            <div onClick={(() => { navigate('/support') })} className='cursor green'>
                                                                Contact
                                                            </div>

                                                        </TableCell>
                                                    </TableRow>
                                                )) :

                                                    <TableRow >
                                                        <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                {/* <img src={nodata} alt='nodata' /> */}
                                                                No Record Found
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>


                                                }
                                                {/* <TableRow>
                                                    <TableCell align='center' colSpan={10}>
                                                        <div className='display-1'>
                                                            2024-05-16
                                                            <div className='green cursor' onClick={(() => { navigate('/orderdetails') })}>
                                                                TXNIAFH879A4D6A
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                                <TableRow className='iner-bg-row'>
                                                    <TableCell align='left'>
                                                        USDT
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        98
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        98
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        1
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        Cancelled
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <div onClick={(() => { navigate('/support') })} className='cursor green'>
                                                            Contact
                                                        </div>

                                                    </TableCell>
                                                </TableRow> */}
                                                {/* <TableRow >
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'>
                                                        <img src={nodata} alt='nodata' />
                                                        No Record Found
                                                    </div>


                                                </TableCell>
                                            </TableRow> */}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="pagination paddin-10 mt-20">
                                    <Pagination count={Math.ceil(myP2p.length / items)} page={page} onChange={handlePageChange} shape="rounded" />
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={1}>
                                <div>
                                    <TableContainer >
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Typessss/coin </TableCell>
                                                    <TableCell align='right'>Flat amount</TableCell>
                                                    <TableCell align='right'>Price</TableCell>
                                                    <TableCell align='right'>Crypto amount</TableCell>
                                                    <TableCell align='right'>Status</TableCell>
                                                    <TableCell align='right'>Price</TableCell>
                                                    <TableCell align='right'>Operation</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align='center' colSpan={10}>
                                                        <div className='nodata'>
                                                            {/* <img src={nodata} alt='nodata' /> */}
                                                            No Record Found
                                                        </div>


                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value2} index={2}>
                                <div>
                                    <TableContainer >
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left'>Type/coin </TableCell>
                                                    <TableCell align='right'>Flat amount</TableCell>
                                                    <TableCell align='right'>Price</TableCell>
                                                    <TableCell align='right'>Crypto amount</TableCell>
                                                    <TableCell align='right'>Status</TableCell>
                                                    <TableCell align='right'>Price</TableCell>
                                                    <TableCell align='right'>Operation</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align='center' colSpan={10}>
                                                        <div className='nodata'>
                                                            {/* <img src={nodata} alt='nodata' /> */}
                                                            No Record Found
                                                        </div>


                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </CustomTabPanel>
                        </CustomTabPanel>
                    </Grid>


                </Grid>
            </div>

        </div>
    )
}
