import { Grid, Box, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import qr from "../../Img/qr-bar.jpg";
import usercalls from "../../Axios";
import consts from "../../constant";
import { toast, ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: " var(--clr-card)",
  borderRadius: "12px",
  "@media(max-width:991.98px)": {
    width: "75%",
  },
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "var(--clr-bcg)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "var(--clr-font)",
  cursor: "pointer",
}));

function PaymentMethod() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const addBenfi = useLocation();

  // const benifi = addBenfi.state

  // console.log(benifi, 'resultReturnlog');

  const path = usercalls()

  const [paymentLists, setPaymentLists] = useState([])


  const getPaymentMethods = async () => {
    try {
      setPaymentLists([])
      const url = `${consts.BackendUrl}/p2p/getPaymentMethod`
      const response = await path.getCallWithToken({ url });
      const result = await response.json();
      if (result?.success) {
        setPaymentLists(result?.result)
        console.log("🚀 ~ getPaymentMethods ~ result?.result:", result?.result)
      } else {
        setPaymentLists([])
      }
    } catch (error) {
      console.log("🚀 ~ getPaymentMethods ~ error:", error)
    }
  }


  useEffect(() => {
    getPaymentMethods()
  }, [])

  const deletePaymentMethod = async (id) => {
    try {
      const url = `${consts.BackendUrl}/p2p/deletePaymentMethod`
      const payload = {
        _id: id
      }
      const response = await path.postCall({ url, payload });
      const result = await response.json();
      if (result?.success) {
        toast.success(result?.message)
        getPaymentMethods()
      }
    } catch (error) {
      console.log("🚀 ~ deletePaymentMethod ~ error:", error)
    }
  }

  const handleDefaultPayment = async (data) => {

    try {
      
      const url = `${consts.BackendUrl}/p2p/defaultpayment`
      const payload = {
        _id: data
      }
      const response = await path.postCall({ url, payload });
      const result = await response.json();
      if (result?.success) {
        toast.success(result?.message)
        getPaymentMethods()
      }

    } catch (error) {
      console.log(error,"Error Setting Default Payment");
    }

  };

  return (
    <div className="Paymentmethod">
      <ToastContainer />
      <Grid container className="justi-center">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
      </Grid>
      <Grid container className="justi-center" sx={{
        display: { xs: 'block' },
      }}>
        {/* <div className="contain-width"> */}

          <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="sti_ck" >
            <div class="ivon-back">
              <Link to="/p2p" className="light-mode-img">
                <img src={darkarrow} />
              </Link>
              <Link to="/p2p" className="dark-mode-img">
                <img src={lightarrow} />
              </Link>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="chip-div">
              <Chip label="P2P Payment Method" color="primary" />
            </div>
            <div className="display-1 add-payment-check" style={{width:"99%"}}>
              <div style={{width:"90%"}} >
                <div className="Payment-text">Payment Methods</div>
                <div className="payment-para">
                  P2P payment methods: When you sell cryptocurrencies, the payment
                  method added will be displayed to buyer as options to accept
                  payment, please ensure that the account owner’s name is
                  consistent with your verified name on Kaavi. You can add up to
                  20 payment methods.
                </div>
              </div>
              <div className="display-2 gap add-pay" onClick={handleOpen}>
                <AddIcon /> Add a Payment Method
              </div>
            </div>
          </Grid>


          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

            {
              paymentLists?.length > 0 &&
              paymentLists?.map((item, index) => {
                return (
                  <div style={{ overflow: "auto" }} key={index}>
                    <div className="added-beneficy-main">
                      <div className="display-2 added-list">
                        <div className="display-1">
                          <div className="paymeny-type-text main">{item?.Type}</div>
                         { item?.isDefault == true ? <Chip label="Default" color="success" /> : null }
                        </div>
                        <div className="display-2 gap">
                       { item?.isDefault == false ? <div className="cursor set-def" onClick={() => { handleDefaultPayment(item?._id) }} >Set As Default</div> : null}
                          <div
                            className="cursor set-def"
                            onClick={() => {
                              navigate("/addpayment", { state : { type : item?.Type == "BANK" ? "0" : item?.Type == "GPAY" ? "1" : "2" , data : item }});
                            }}
                          >
                            Edit
                          </div>
                          <div className="cursor set-def" style={{ background : "red" }} onClick={() => { deletePaymentMethod(item?._id) }} >Delete</div>
                        </div>
                      </div>
                      <div className="beneficary-in-detail">
                        <div className="name">
                          Name
                          <div className="usrname">{item?.Name}</div>
                        </div>

                        <div className="name">
                          {item?.Type == "BANK" ? "Account Number" : " UPI ID"}
                          <div className="usrname">{item?.Type == "BANK" ? item?.Account_number : item?.Upi_id}</div>
                        </div>
                        <div className="name">
                          {item?.Type == "BANK" ? "Ifsc Code" : " QR Image"}
                          <div className="usrname qr-image-upload">
                            {
                              item?.Type == "BANK" ?
                                <p>{item?.Ifsc_code}</p>
                                :
                                <img src={item?.Qr_code} />
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
             
              
            }
            {
               paymentLists && 
               <div className="display-3 payment-para" > <br /><br /> No Payment Method Records Found</div>
            }


          </Grid>




        {/* </div> */}
      </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 1000,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  className="close-menu text-end cursor"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </div>
                <div className="select-payment-pop-text text-left">
                  Select Payment Methods
                </div>
                <div className="recommend">Recommended</div>
                <div className="method-stack">
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={3}
                    className="display-2"
                  >
                    <Item 
                      // style={{ cursor: "not-allowed", pointerEvents : "none", opacity : "0.5" }}
                      className="item-bg"
                      onClick={() => {
                        navigate("/addpayment", { state: { type : "0" } });
                      }}
                    >
                      BANK TRANSFER
                    </Item>
                    <Item
                      className="item-bg"
                      onClick={() => {
                        navigate("/addpayment", { state: { type : "1" } });
                      }}
                    >
                      GPAY
                    </Item>
                    <Item
                      className="item-bg"
                      onClick={() => {
                        navigate("/addpayment", { state:  { type : "2" } });
                      }}
                    >
                      UPI
                    </Item>
                  </Stack>
                </div>
              </Box>
            </Fade>
          </Modal>
    </div>
  );
}

export default PaymentMethod;
