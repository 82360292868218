import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "./TradeHistory.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GradingIcon from "@mui/icons-material/Grading";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import nodata from "../../Img/thankyou.gif";
import Pagination from "@mui/material/Pagination";
import usercalls from "../../Axios";
import consts from '../../constant';
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import loading from '../../Img/favicon.png'



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabs = [
  { name: "Order History", },
  { name: "Deposit History", },
  { name: "Withdraw History", },
];

// console.log(tabs, "tabs");

function order(pair, side, type, price, remaining, amount, quality, status) {
  return { pair, side, type, price, remaining, amount, quality, status };
}

const orderdatarows = [
  order(
    "BTC",
    "Buy",
    "Limit",
    "43102.65000000",
    "0.00100000",
    "0.00000000",
    "43.10265000",
    "Completed"
  ),
  order(
    "AAVE_USDT",
    "Sell",
    "Limit",
    "23102.65000000",
    "0.00200000",
    "0.00100000",
    "43.10265000",
    "Completed"
  ),
];
const sort = orderdatarows.sort();
// console.log(sort)

function deposit(currency, id, sender, receiver, amount, status) {
  return { currency, id, sender, receiver, amount, status };
}

const depositdatarows = [
  deposit(
    "BTC",
    "0ccae35148fb2e81fe03d54a69d6e942d7672539a42e52d698fad24e8c62",
    "admindeposit",
    "1BEdCSdHz7xxVTr2M5wwUUvp4zUJcUP3mD",
    "500.00000000",
    "Success"
  ),
];

function withdraw(
  id,
  asset,
  sender,
  receiver,
  network,
  dateTime,
  amount,
  fee,
  describe,
  status
) {
  return {
    id,
    asset,
    sender,
    receiver,
    network,
    dateTime,
    amount,
    fee,
    describe,
    status,
  };
}

const withdrawdatarows = [
  withdraw(
    "2589099346f2246",
    "BTC",
    "1BEdCSdHz7xxVTr2M5wwUUvp4zUJcUP3mD",
    "1H3mGgGFv3cTNjkfwymPVRiPRQezhuecb3",
    "coin",
    "27/03/2024 16:11:26",
    "20.00000000",
    "0.1",
    "Internal Transfer 39",
    "Success"
  ),
];

const TradeHistory = () => {
  const [value, setValue] = React.useState(0);
  const path = usercalls()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [buysell, setbuysell] = useState("");

  const handleChangesetbuysell = (event) => {
    setbuysell(event.target.value);
  };

  const [pair, setpair] = useState(1);

  const handleChangepair = (event) => {
    setpair(event.target.value);
  };

  const [historyList, setHistoryList] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openLoad, setOpenLoad] = useState(false);
  const [depositList, setDepositList] = useState([]);
  const [withdrawList, setWithdrawList] = useState([]);

  const getHistory = async () => {
    try {
      setOpenLoad(true);
      const url = `${consts.BackendUrl}/binanceTrade/orderHistory`
      const payload = {
        pair: "all",
        orderType: "all"
      }
      // console.log(payload, 'payloada');
      const response = await path.postCall({ url, payload });
      const result = await response.json();

      if (result?.success) {
        setHistoryList(result?.result);
      }
    } catch (error) {
      console.log("🚀 ~ getHistory ~ error:", error)
    } finally {
      setOpenLoad(false);
    }
  }

  const getWithdrawList = async () => {
    try {

      const url = `${consts.BackendUrl}/withdrawList`
      const response = await path.getCallWithToken({ url });
      const result = await response.json();

      console.log(result,"deposit list");
      
      if (result?.success) {
        setWithdrawList(result?.result);
      }

    } catch (error) {
      console.log("🚀 ~ getHistory ~ error:", error)
    }

  }


  useEffect(() => {
    getHistory();
    getWithdrawList();
  }, []);

  const paginatedHistory = historyList.slice((page - 1) * perPage, page * perPage);
  const paginatedwithdraw = withdrawList.slice((page - 1) * perPage, page * perPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {

    try {
      const url = `${consts.BackendUrl}/binanceTrade/orderHistory`
      const payload = {
        pair: "notall",
        orderType: "all",
        side: buysell,
        startdate: startDate,
        enddate: endDate
      }
    
      const response = await path.postCall({ url, payload });
      const result = await response.json();

      if (result?.success) {
        setHistoryList(result?.result);
      }
    } catch (error) {
      console.log("🚀 ~ getHistory ~ error:", error)
    }

  };

  const handleStartDateChange = (data) => {

    const date = new Date(data);   
    date.setUTCHours(0, 0, 0, 0);  
    date.setDate(date.getDate() + 1);      
    const isoDate = date.toISOString();   
    setStartDate(isoDate);

  };
  
  const handleEndDateChange = (data) => {

    const date = new Date(data);   
    date.setUTCHours(23, 59, 59, 999);  
    date.setDate(date.getDate() + 1);         
    const isoDate = date.toISOString();   
    setEndDate(isoDate);

  };

  const handleReset = async () => {
    getHistory();
    setbuysell("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <div className="payment">
      <Grid container className="justi-center">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
          <Typography variant="h1" className="cmn-text">
            Trade History
          </Typography>

          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="payment-left-main ticket-box">
                <div className="display-1">
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className="vertiacal-tab-payment"
                  >
                    {tabs.map((row, ind) => {
                      return (
                        <Tab
                          iconPosition="start"
                          icon={row.icon}
                          label={row.name}
                          {...a11yProps(ind)}
                        />
                      );
                    })}
                  </Tabs>
                </div>
                <TabPanel value={value} index={0} class="order-history">
                  <div className='tab-1'>
                    <div>
                    </div>
                  </div>
                  <Grid container >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='history-inputs'>
                      <Grid container className='searchfrm row' spacing={2}>
                        <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker label="Start date" onChange={(date) => handleStartDateChange(date)} />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']}>
                              <DatePicker label="End date" onChange={(date) => handleEndDateChange(date)} />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                          <FormControl size="small" className="slide-sel">
                            <InputLabel id="demo-simple-select-label">Select Side</InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={buysell}
                              label="Select Side"
                              onChange={handleChangesetbuysell}
                            >
                              {/* <MenuItem value={10}>All side</MenuItem> */}
                              <MenuItem value={"BUY"}>Buy</MenuItem>
                              <MenuItem value={"SELL"}>Sell</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                          <FormControl size="small" className='pt'>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={pair}
                              onChange={handleChangepair}
                              // onClick={sort}
                            >
                              <MenuItem value={1}>All pairs</MenuItem>
                              <MenuItem value={2}>ARB_USDT</MenuItem>
                              <MenuItem value={3}>MANA_USDT</MenuItem>
                              <MenuItem value={4}>MBOX_USDT</MenuItem>
                              <MenuItem value={5}>AAVE_USDT</MenuItem>
                              <MenuItem value={6}>MKR_BTC</MenuItem>
                              <MenuItem value={7}>LINK_USDT</MenuItem>
                              <MenuItem value={8}>ETH_BTC</MenuItem>
                              <MenuItem value={9}>BNB_USDT</MenuItem>
                              <MenuItem value={10}>TRX_USDT</MenuItem>
                              <MenuItem value={11}>ETH_USDT</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid> */}
                        <Grid xs={12} sm={12} md={4} lg={3} xl={3}>
                          <div class="form-group">
                            <button onClick={() => { handleSearch() }} type="submit" class="btn sitebtn cmn-btn">Search</button>
                            <button onClick={() => { handleReset() }} class="btn sitebtn cmn-btn">Reset</button>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow className='row-head'>
                                <TableCell className='head-bg'>Pair</TableCell>
                                <TableCell className='head-bg'>Side</TableCell>
                                <TableCell className='head-bg'>Type</TableCell>
                                <TableCell className='head-bg'>Price</TableCell>
                                <TableCell className='head-bg'>Order Id</TableCell>
                                {/* <TableCell className='head-bg'>Amount</TableCell> */}
                                <TableCell className='head-bg'>Total Qty.</TableCell>
                                <TableCell className='head-bg'>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedHistory.length > 0 ? paginatedHistory?.map((orderdatarows, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" className='body-bg'>{orderdatarows?.others?.symbol}</TableCell>
                                  <TableCell className='body-bg overflow'>{orderdatarows?.side}</TableCell>
                                  <TableCell className='body-bg overflow'>{orderdatarows?.type}</TableCell>
                                  <TableCell className='body-bg overflow'>{orderdatarows?.price}</TableCell>
                                  <TableCell component="th" className='body-bg'>{orderdatarows?.orderId}</TableCell>
                                  {/* <TableCell className='body-bg overflow'>{orderdatarows.amount}</TableCell> */}
                                  <TableCell className='body-bg overflow'>{orderdatarows?.quantity}</TableCell>
                                  <TableCell className='body-bg overflow'>{orderdatarows?.status}</TableCell>

                                </TableRow>
                              )) :
                                <TableRow>
                                  <TableCell colSpan={6} className='body-bg no-data'>No data found</TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <div className="pagination paddin-10 mt-20">
                        <Pagination
                          count={Math.ceil(historyList.length / perPage)}
                          page={page} onChange={handlePageChange} shape="rounded" />
                      </div>


                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="tab-1"></div>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow className="row-head">
                              <TableCell className="head-bg">
                                Currency
                              </TableCell>
                              <TableCell className="head-bg">Txn ID</TableCell>
                              <TableCell className="head-bg">Sender</TableCell>
                              <TableCell className="head-bg">
                                Receiver
                              </TableCell>
                              <TableCell className="head-bg">Amount</TableCell>
                              <TableCell className="head-bg">Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {depositdatarows?.map((depositdatarows, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" className="body-bg">
                                  {depositdatarows.currency}
                                </TableCell>
                                <TableCell className="body-bg overflow">
                                  {depositdatarows.id}
                                </TableCell>
                                <TableCell className="body-bg overflow">
                                  {depositdatarows.sender}
                                </TableCell>
                                <TableCell className="body-bg overflow">
                                  {depositdatarows.receiver}
                                </TableCell>
                                <TableCell className="body-bg overflow">
                                  {depositdatarows.amount}
                                </TableCell>
                                <TableCell className="body-bg overflow">
                                  {depositdatarows.status}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell align="center" colSpan={6}>
                                <div className="nodata">
                                  {/* <img src={nodata} alt="nodata" /> */}
                                  No Record Found
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="tab-1"></div>
                  <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow className="row-head">
                              <TableCell className="head-bg">Txn ID</TableCell>
                              <TableCell className="head-bg">Assets</TableCell>
                              {/* <TableCell className="head-bg">Sender</TableCell> */}
                              {/* <TableCell className="head-bg">
                                Receiver
                              </TableCell> */}
                              <TableCell className="head-bg">Network</TableCell>
                              <TableCell className="head-bg">
                                Date / Time
                              </TableCell>
                              <TableCell className="head-bg">Amount</TableCell>
                              {/* <TableCell className="head-bg">
                                Admin Fee
                              </TableCell>
                              <TableCell className="head-bg">
                                Description
                              </TableCell> */}
                              <TableCell className="head-bg">Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            { paginatedwithdraw.length > 0 ? 
                            paginatedwithdraw?.map(
                              (withdrawdatarows, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" className="body-bg">
                                    {withdrawdatarows?.Address.slice(0,5)+"..."+withdrawdatarows?.Address.slice(-5)}
                                  </TableCell>
                                  <TableCell className="body-bg overflow">
                                    {withdrawdatarows?.Coinname}
                                  </TableCell>
                                  {/* <TableCell className="body-bg overflow">
                                    {withdrawdatarows.sender}
                                  </TableCell>
                                  <TableCell className="body-bg overflow">
                                    {withdrawdatarows.receiver}
                                  </TableCell> */}
                                  <TableCell component="th" className="body-bg">
                                    {withdrawdatarows?.Network}
                                  </TableCell>
                                  <TableCell className="body-bg overflow">
                                  {withdrawdatarows?.createdAt?.split("T")[0]}: {withdrawdatarows?.createdAt?.split("T")[1].split(".")[0]}
                                  </TableCell>
                                  <TableCell className="body-bg overflow">
                                    {withdrawdatarows?.Amount}
                                  </TableCell>
                                  {/* <TableCell className="body-bg overflow">
                                    {withdrawdatarows.fee}
                                  </TableCell>
                                  <TableCell component="th" className="body-bg">
                                    {withdrawdatarows.describe}
                                  </TableCell> */}
                                  <TableCell className="body-bg overflow">
                                    {withdrawdatarows?.Status}
                                  </TableCell>
                                </TableRow>
                              )
                            ) : 
                            <TableRow>
                              <TableCell align="center" colSpan={6}>
                                <div className="nodata">
                                  {/* <img src={nodata} alt="nodata" /> */}
                                  No Record Found
                                </div>
                              </TableCell>
                            </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <div className="pagination paddin-10 mt-20">
                        <Pagination
                          count={Math.ceil(withdrawList.length / perPage)}
                          page={page} onChange={handlePageChange} shape="rounded" />
                      </div>
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={openLoad}
                // onClick={handleCloseLoad}
            >

                <div class="loading-bar">
                    <img src={loading} alt="loading" width="80px" height="80px" style={{ marginTop: '15px' }} />
                </div>

            </Backdrop>
    </div>
  );
};

export default TradeHistory;
