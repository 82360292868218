import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./Login.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import lightlogo from "../../Img/light-mode-logo.png";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import usercalls from "../../Axios";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';

export default function SignInSide() {


  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const location = useLocation()
  const path = usercalls()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [load, setLoad] = useState(false)

  const navigate = useNavigate();
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  const handleSubmit = async () => {
    try {
      setLoad(true)
      const url = `${consts.BackendUrl}/login`
      const payload = {
        email: email,
        password: password
      }
      const response = await path.postCallWithoutAuthToken({ url, payload });
      const result = await response.json();
      // console.log(result, 'results');
      if (result.success) {
        // const url = `${consts.BackendUrl}/createNewWallet`
        // const payload = {
        //   userId: result?.result?.user?._id
        // }
        // const response = await path.postCallWithoutAuthToken({ url, payload });
        // const Walletresult = await response.json();
        // if (Walletresult.success) {
        if (result?.result?.user?.f2A_Status === 'true') {
          navigate('/2fa', { state: result?.result?.token })
        }
        else {
          toast.success(result.message)
          localStorage.setItem('Rikosta', result?.result?.token)
          localStorage.setItem('use', result?.result?.user?._id)
          localStorage.setItem('master', result?.result?.user?.isMaster)
          setTimeout(() => {
            setLoad(false)
            navigate(`/trade/${consts?.MainPair}`)
          }, 2000)
        }
        // }
        // else {
        //   setLoad(false)
        // }
      }
      else {
        setLoad(false)
        toast.error(result.message)
      }
    } catch (error) {
      console.log("🚀 ~ login ~ error:", error);
    }
  };

  useEffect(() => {
    // console.log(localStorage.getItem('Rikosta'), 'loginb');

    if (localStorage.getItem('Rikosta') !== null) {
      navigate(`/trade/${consts?.MainPair}`)
    }
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="Login">
        <div className="logo">
          <Link to="/">
            {" "}
            <img src={lightlogo} />
          </Link>
        </div>
        <Grid
          container
          component="main"
          sx={{ justifyContent: "center" }}
        >
          <Grid item xs={11} sm={8} md={7} lg={7} xl={6}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={10} md={9} lg={6} xl={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    padding: "25px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography component="h1" variant="h5" className="text-center c-accout" sx={{ mt: 1, mb: 3 }}>
                    Login to your account
                  </Typography>
                  <Box
                    // component="form"
                    // noValidate
                    // onSubmit={handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <div className="email-fill">
                      <div className="email-name">
                        Email
                      </div>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        // label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                    </div>

                    <div className="email-fill " style={{ marginTop: "10px" }} >
                      <div className="display-2 mt-10" style={{ width: '100%' }}>
                        <div className="email-name">
                          Password
                        </div>
                        <div className="alraedy">
                          <Link to="/forgot" variant="body2">
                            <span>Forgot Password?</span>
                          </Link>
                        </div>

                      </div>

                      <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                        {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => { setPassword(e.target.value) }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={
                                  showPassword ? 'hide the password' : 'display the password'
                                }
                                onClick={handleClickShowPassword}

                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        // label="Password"
                        />
                      </FormControl>
                    </div>


                    <div className="login-btn">
                      {
                        load ?
                          <Button
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                          >
                            Processing...
                          </Button> :
                          <Button
                            type="submit"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            // onClick={() => {
                            //   navigate("/trade");
                            // }}
                            onClick={() => { handleSubmit() }}
                          >
                            Login now
                          </Button>
                      }
                    </div>
                    <div className="text-center accnt-sign mt-10">
                      <Link to="/register" variant="body2">
                        Don't have an account? <span>Sign Up</span>
                      </Link>
                    </div>

                    <div className="text-center accnt-sign mt-10">
                      <Link to="/resend" variant="body2">
                        Resend Verification Email? <span>Resend</span>
                      </Link>
                    </div>

                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
