import { Grid, Box, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Navigate, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import usercalls from "../../Axios";
import consts from '../../constant';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const names = [
    'BANK TRANSFER',
    'UPI',
    'GPAY',
    'GPAy-PHILLIPINES',

];

function PostNewAd() {

    const path = usercalls();
    const [personName, setPersonName] = React.useState([]);
    const [fromAsset, setFromAsset] = useState("USDT");
    const [toAsset, setToAsset] = useState("INR");
    const [price, setPrice] = useState("");
    const [volume, setVolume] = useState("");
    const [minLimit, setMinLimit] = useState("");
    const [maxLimit, setMaxLimit] = useState("");
    const [load, setLoad] = useState(false);
    const [p2pAssets, setP2pAsssets] = useState([]);
    const [priceError, setPriceError] = useState("");
    const [volumeError, setVolumeError] = useState("");
    const [minLimitError, setMinLimitError] = useState("");
    const [maxLimitError, setMaxLimitError] = useState("");
    const [payMethod, setPayMethod] = useState("");

    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setPayMethod(value.join('-'))
    };


    const navigate = useNavigate()

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        // console.log(newValue,"newValue");
        setValue(newValue);
    };
    const [age, setAge] = useState("USDT");

    const handleChange2 = (event) => {
        setFromAsset(event.target.value)
        setAge(event.target.value);
    };

    const [payment, setPayment] = useState("INR");
    const [paymentError, setPaymentError] = useState("");
    const [allPayments, setAllPayments] = useState([]);

    const handleChangePayment = (event) => {
        setPayment(event.target.value);
    };


    const handleCreatePost = async () => {
        if (personName.length == 0) {
            return setPaymentError("Please Select Payment Method");
        } else if (price == "") {
            return setPriceError("Please Enter Price");
        } else if (volume == "") {
            return setVolumeError("Please Enter Volume");
        } else if (minLimit == "" || Number(minLimit) <= 0) {
            return setMinLimitError("Please Enter Valid Minimum Limit");
        } else if (maxLimit == "") {
            return setMaxLimitError("Please Enter Maximum Limit");
        } else if (Number(maxLimit) > Number(volume)) {
            return setMaxLimitError("Invalid Maximum Limit");
        } else {

            try {

                setLoad(true);
                var payload = {
                    type: value == 0 ? 'BUY' : 'SELL',
                    fromAsset: fromAsset,
                    toAsset: toAsset,
                    payments: payMethod,
                    price: price,
                    volume: volume,
                    minLimit: minLimit,
                    maxLimit: maxLimit
                };

                // console.log(payload, "payloadpayload");
                const url = `${consts.BackendUrl}/binanceTrade/createp2p`;

                const response = await path.postCall({ url, payload });
                const result = await response.json();

                if (result?.success) {
                    toast.success(result?.message);
                    setPrice("");
                    setVolume("");
                    setMinLimit("");
                    setMaxLimit("");
                    setPersonName([]);
                    setTimeout(() => {
                        navigate("/p2p");
                    }, 500);
                } else {
                    toast.error(result?.message);
                }

            } catch (error) {
                setPrice("");
                setVolume("");
                setMinLimit("");
                setMaxLimit("");
                setPersonName([]);
                console.log(error, "error creating post");
            } finally {
                setLoad(false);
            }

        }

    };

    const getPaymentMethods = async () => {
        try {

            const url = `${consts.BackendUrl}/p2p/getPaymentMethod`;

            const response = await path.getCallWithToken({ url });
            const result = await response.json();

            if (result?.success && result?.result.length > 0) {
                const paymentTypes = [...new Set(result.result.map((item) => item.Type))];
                setAllPayments(paymentTypes);
            } else if (result?.result.length == 0) {
                toast.error("Please Add a Payment Method");
                setTimeout(() => {
                    navigate("/paymentMethod");
                }, 1000)
            } else {
                toast.error(result?.message);
            }

        } catch (error) {
            console.log(error, "Error Getting Payment Methods");
        }
    };

    const getP2pAssets = async () => {

        try {

            const url = `${consts.BackendUrl}/p2p/getp2passets`;

            const response = await path.getCallWithToken({ url });
            const result = await response.json();

            if (result?.success) {
                setP2pAsssets(result?.result);
            } else {
                toast.error(result?.message);
            }

        } catch (error) {
            console.log(error, "Error Getting P2P Assets");
        }

    };

    useEffect(() => {
        getPaymentMethods()
        getP2pAssets()
    }, []);


    return (
        <div className='post-new-Ad'>
            <ToastContainer />
            <Grid container className='justi-center'>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='sti_ck'>
                <div class="ivon-back">
                        <Link to="/p2p" className="light-mode-img">
                            <img src={darkarrow} />
                        </Link>
                        <Link to="/p2p" className="dark-mode-img">
                            <img src={lightarrow} />
                        </Link>
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                   
                    <div className='post-ad-title text-center'>

                        Post Normal Ad
                    </div>

                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    {/* <div>
                        Sell Type & Price
                    </div> */}
                    <div className='postnew-ad buy-sell-main-tab2 ' >
                        <Box sx={{ padding: '10px 0px' }} className="ad_tab" >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label=" I Want to Buy" className={value === 0 && 'buy-bg-clr'} {...a11yProps(0)} />
                                <Tab label='I Want to Sell' className={value === 1 && 'buy-bg-clr'} {...a11yProps(1)} />

                            </Tabs>
                        </Box>
                        <div className='asset-main'>
                            <CustomTabPanel value={value} index={value == 0 ? 0 : 1}>
                                <Grid container >
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                                    {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={age}
                                                        // label="All Coin"
                                                        onChange={handleChange2}
                                                        className='p2p-select'
                                                    >
                                                        {p2pAssets.length > 0 ? p2pAssets.map((row, ind) => (
                                                            <MenuItem value={row?.symbol}>{row?.symbol}</MenuItem>
                                                        )) :
                                                            <MenuItem disabled={true} value={""}>No Pairs</MenuItem>
                                                        }
                                                        {/* <MenuItem value={"USDT"}>USDT</MenuItem>
                                                        <MenuItem value={"INR"}>INR</MenuItem> */}
                                                        {/* <MenuItem value={30}>USD</MenuItem>
                                                        <MenuItem value={40}>Euro</MenuItem> */}

                                                    </Select>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={1} md={1} lg={1} xl={1}>
                                        <div className='display-3'>
                                            <CompareArrowsIcon />
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                                    {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={payment}
                                                        // label="All Coin"
                                                        className='p2p-select'
                                                        onChange={handleChangePayment}
                                                    >

                                                        <MenuItem value={"INR"}>INR</MenuItem>
                                                        {/* <MenuItem value={20}>Net Banking</MenuItem>
                                                        <MenuItem value={30}>Gpay</MenuItem>
                                                        <MenuItem value={40}>UPI</MenuItem> */}

                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='pric-vol-text'>
                                            Select Payment Method
                                        </div>
                                        <FormControl sx={{ m: 1, width: { xs: '100%', sm: '300px' } }} className='multi-select'>
                                            {/* <InputLabel id="demo-multiple-checkbox-label">Select Payment Method</InputLabel> */}
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={personName}
                                                onChange={(e) => { handleChange3(e); setPaymentError("") }}
                                                input={<OutlinedInput placeholder='Select Payment Method' />}
                                                className='p2p-select'
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {allPayments.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                                {/* {allPayments.map((name, index) => (
                                                    <MenuItem key={index} value={name?.Type}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name?.Type} />
                                                    </MenuItem>
                                                ))} */}
                                            </Select>
                                        </FormControl>
                                        <div> <p style={{ color: "red" }}> {paymentError != "" ? paymentError : ""} </p> </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Price
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField type='number' onChange={(e) => { setPrice(e?.target?.value); setPriceError("") }} value={price} id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                        <div> <p style={{ color: "red", textAlign: "center" }}> {priceError != "" ? priceError : ""} </p> </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Volume
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField type='number' onChange={(e) => { setVolume(e?.target?.value); setVolumeError("") }} value={volume} id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                        <div> <p style={{ color: "red", textAlign: "center" }}> {volumeError != "" ? volumeError : ""} </p> </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Minimum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField type='number' onChange={(e) => { setMinLimit(e?.target?.value); setMinLimitError("") }} value={minLimit} id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                        <div> <p style={{ color: "red", textAlign: "center" }}> {minLimitError != "" ? minLimitError : ""} </p> </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Maximum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField type='number' onChange={(e) => { setMaxLimit(e?.target?.value); setMaxLimitError("") }} value={maxLimit} id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                        <div> <p style={{ color: "red", textAlign: "center" }}> {maxLimitError != "" ? maxLimitError : ""} </p> </div>
                                    </Grid>
                                    <Grid xs={12} sm={2} md={2} lg={2} xl={2}>
                                        <div className='post-ad'>
                                            <Button disabled={load} onClick={handleCreatePost} variant='contained'>
                                                Post Ad
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>


                                </div>
                            </CustomTabPanel>
                        </div>
                        {/* <div className='asset-main'>
                            <CustomTabPanel value={value} index={1}>
                                <Grid container>
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small"> */}
                        {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                        {/* <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={age} */}
                        {/* // label="All Coin"
                                                        // onChange={handleChange2}
                                                        // className='p2p-select' */}
                        {/* > */}

                        {/* <MenuItem value={10}>USDT</MenuItem> */}
                        {/* <MenuItem value={20}>INR</MenuItem>
                                                        <MenuItem value={30}>USD</MenuItem>
                                                        <MenuItem value={40}>Euro</MenuItem> */}

                        {/* </Select>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={1} md={1} lg={1} xl={1}>
                                        <div className='display-3'>
                                            <CompareArrowsIcon />
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div> */}
                        {/* <FormControl sx={{ m: 1, minWidth: '100%' }} size="small"> */}
                        {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                        {/* <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={payment}
                                                        // label="All Coin"
                                                        onChange={handleChangePayment}
                                                        className='p2p-select' */}
                        {/* > */}

                        {/* <MenuItem value={10}>INR</MenuItem> */}
                        {/* <MenuItem value={20}>Net Banking</MenuItem>
                                                        <MenuItem value={30}>Gpay</MenuItem>
                                                        <MenuItem value={40}>UPI</MenuItem> */}

                        {/* </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='pric-vol-text'>
                                            Select Payment Method
                                        </div>
                                        <FormControl sx={{ m: 1, width: { xs: '100%', sm: '300px' } }} className='multi-select'> */}
                        {/* <InputLabel id="demo-multiple-checkbox-label">Select Payment Method</InputLabel> */}
                        {/* <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={personName}
                                                onChange={handleChange3}
                                                input={<OutlinedInput placeholder='Select Payment Method' />}

                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                                className='p2p-select'
                                            >
                                                {names.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Price
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Volume
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Minimum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Maximum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={2} md={2} lg={2} xl={1}>
                                        <div className='post-ad'>
                                            <Button variant='contained'>
                                                Post Ad
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>


                                </div>
                            </CustomTabPanel>
                        </div> */}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default PostNewAd
