import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./Login.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import lightlogo from "../../Img/light-mode-logo.png";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Try } from "@mui/icons-material";
import usercalls from "../../Axios";
import consts from "../../constant";
import { toast, ToastContainer } from 'react-toastify';

export default function Verify() {


    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const navigate = useNavigate();


    const [otp, setOtp] = useState(Array(6).fill(''));
    const path = usercalls()
    const [load, setLoad] = useState(false)

    const joinedString = otp.join('');

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (/^\d?$/.test(value)) { // Allow only single digit
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Automatically move to the next input field when a digit is entered
            if (value && index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move to the previous input field on backspace
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get("email"),
    //         password: data.get("password"),
    //     });
    // };

    const handleSubmit = async () => {
        try {
            setLoad(true)
            const url = `${consts.BackendUrl}/verifyotp`
            const payload = {
                email: localStorage.getItem('email'),
                otp: joinedString
            }
            const response = await path.postCallWithoutAuthToken({ url, payload });
            const result = await response.json();
            // console.log(result, 'result');
            if (result.success) {
                toast.success(result?.message)
                localStorage.removeItem('email')
                setTimeout(() => {
                    setLoad(false)
                    navigate('/')
                }, [2000])
            }
            else {
                if (result?.message === 'Already Email Verified') {
                    setLoad(false)
                    toast.error(result.message)
                    setOtp(Array(6).fill(''))
                    setTimeout(() => {
                        navigate('/')
                    }, 2000)
                }
                else {
                    setLoad(false)
                    toast.error(result.message)
                    setOtp(Array(6).fill(''))
                }
            }

        } catch (error) {
            setLoad(false)
            console.log("🚀 ~ verify otp ~ error:", error);

        }
    };

    return (
        <>
            <ToastContainer />
            <div className="Login">
                <div className="logo">
                    <Link to="/">
                        {" "}
                        <img src={lightlogo} />
                    </Link>
                </div>
                <Grid
                    container
                    component="main"
                    sx={{ justifyContent: "center" }}
                >
                    <Grid item xs={11} sm={8} md={7} lg={7} xl={6}>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} sm={10} md={9} lg={6} xl={5}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                        padding: "25px",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography component="h1" variant="h5" className="text-center c-accout" sx={{ mt: 1, mb: 3 }}>
                                        Verify Email?
                                    </Typography>
                                    <Box
                                        // component="form"
                                        // noValidate
                                        // onSubmit={handleSubmit}
                                        sx={{ mt: 1 }}
                                    >
                                        <div className="email-name">
                                            Enter Otp
                                        </div>
                                        <div className='email-fill mt-10 display-1'>
                                            {/* <label className='display-1 fllname'>Email</label> */}
                                            {otp.map((digit, index) => (
                                                <TextField
                                                    key={index}
                                                    id={`otp-input-${index}`}
                                                    value={digit}
                                                    onChange={(e) => handleChange(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}

                                                    variant="outlined"
                                                    className='otp-field'
                                                />
                                            ))}

                                        </div>

                                        <div className="login-btn br-10">
                                            {
                                                load ?
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Processing...
                                                    </Button> :
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        sx={{ mt: 3, mb: 2 }}
                                                        onClick={() => { handleSubmit() }}
                                                    >
                                                        Verify
                                                    </Button>
                                            }

                                        </div>


                                        <div className="text-center accnt-sign mt-10">
                                            <Link to="/register" variant="body2">
                                                Don't have an account? <span>Sign Up</span>
                                            </Link>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
