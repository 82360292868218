// TradingViewWidget.jsx
// import React, { useEffect, useRef, memo } from 'react';

// function Tradeview() {
//     const container = useRef();

//     useEffect(
//         () => {
//             const script = document.createElement("script");
//             script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
//             script.type = "text/javascript";
//             script.async = true;
//             script.innerHTML = `
//         {
//           "width": "100%",
//           "height": "610",
//           "symbol": "NASDAQ:AAPL",
//           "interval": "D",
//           "timezone": "Etc/UTC",
//           "theme": "${window.localStorage.getItem('theme') === 'true' ? "light" : "dark"}" ,
//           "style": "1",
//           "locale": "en",
//           "allow_symbol_change": true,
//           "calendar": false,
//           "support_host": "https://www.tradingview.com"
//         }`;
//             container.current.appendChild(script);
//         },
//         []
//     );

//     return (
//         <div className="tradingview-widget-container" ref={container}>
//             <div className="tradingview-widget-container__widget"></div>
//             <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
//         </div>
//     );
// }

// export default memo(Tradeview);


// import TradingViewWidget, { Themes } from 'react-tradingview-widget';

// const Tradeview = ({ pair }) => (
//     <TradingViewWidget symbol={`Binance:${pair}`} id='tdview' theme={Themes.DARK} allow_symbol_change={false}
//         overrides={{
//             "paneProperties.background": "#000000", // Pane (chart) background color
//             "paneProperties.vertGridProperties.color": "#1c1c1c", // Vertical grid lines (dark gray)
//             "paneProperties.horzGridProperties.color": "#1c1c1c", // Horizontal grid lines (dark gray)
//             "scalesProperties.textColor": "#ffffff", // Text color (white) for axes
//             "paneProperties.crossHairProperties.color": "#3a3a3a", // Crosshair color
//         }}
//         studies_overrides={{
//             // Example: Customize indicator colors (optional)
//             "paneProperties.background": "#000000", // Pane (chart) background color
//             "volume.volume.color.0": "#FF0000", // Down volume bar color
//             "volume.volume.color.1": "#00FF00", // Up volume bar color
//         }}
//     />
// );

// export default Tradeview;
import React, { useEffect } from "react";

const Tradeview = ({ pair }) => {
    useEffect(() => {
        if (pair != undefined) {
            // Check if TradingView script is already added
            if (!document.getElementById("tradingview-widget-script")) {
                const script = document.createElement("script");
                script.id = "tradingview-widget-script";
                script.src = "https://s3.tradingview.com/tv.js";
                script.async = true;
                document.body.appendChild(script);
                script.onload = () => {
                    initializeTradingViewWidget(pair);
                };
            } else {
                // If script already exists, initialize widget
                initializeTradingViewWidget(pair);
            }

            return () => {
                // Cleanup widget container on unmount
                const widgetContainer = document.getElementById("tradingview_chart");
                if (widgetContainer) {
                    widgetContainer.innerHTML = "";
                }
            };
        }
    }, [pair]);

    const initializeTradingViewWidget = (pair) => {
        if (pair != undefined) {
            if (window.TradingView) {
                new window.TradingView.widget({
                    symbol: `BINANCE:${pair}`, // Dynamic pair symbol
                    container_id: "tradingview_chart",
                    width: "100%",
                    theme: "dark", // Dark theme
                    interval: "D", // Timeframe (1 day)
                    timezone: "Etc/UTC",
                    style: "1", // Candlestick chart
                    locale: "en",
                    toolbar_bg: "#000000", // Toolbar background (black)
                    backgroundColor: "#000000",
                    enable_publishing: false,
                    allow_symbol_change: false,
                });
            }
        }

    };

    return (
        <div id="tradingview_widget_container" style={{ width: "100%" }}>
            <div id="tradingview_chart" />
        </div>
    );
};

export default Tradeview;

