import React, { useEffect, useState } from "react";
import "./P2P.scss";
import { Grid, Box, Button, InputLabel } from "@mui/material";
import Header from "../../Components/Header/Header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PaymentIcon from "@mui/icons-material/Payment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import nodata from "../../Img/thankyou.gif";
import Chip from "@mui/material/Chip";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GoogleIcon from '@mui/icons-material/Google';
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import usercalls from "../../Axios";
import consts from '../../constant';
import Pagination from '@mui/material/Pagination';
import loading from '../../Img/favicon.png'
import { toast, ToastContainer } from "react-toastify";




const buyp2p = [
  { name: "Sankar", order: "13 Orders", live: "online", amt: "98.65 INR" },
  { name: "Vijay", order: "13 Orders", live: "offline", amt: "98.65 INR" },
  { name: "Kishpre", order: "13 Orders", live: "online", amt: "98.65 INR" },
  { name: "Barath", order: "13 Orders", live: "offline", amt: "98.65 INR" },
];


const primaryTabData = [
  {
    label: "Buy",
    sublabel: "USDT"
  },
  {
    label: "Sell",
    sublabel: "BTC"
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "var(--clr-card)",
  borderRadius: "12px",
  height: "500px",
  overflow: "scroll",
  "@media(max-width:991.98px)": {
    width: "75%",
  },
  boxShadow: 24,
  p: 4,
};


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



function P2P() {
  const path = usercalls();
  const [value, setValue] = useState(0);
  const [isTcChecked, setIsTcChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [dis, setDis] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDis(true);
    setOpen(false);
    setQtyError("");
    setIRecieve(0);
    setIPay(0);
  }

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [p2pAssets, setP2pAsssets] = useState([]);
  const [Buy, setBuy] = useState(false);
  const [paymentt, setPaymentt] = useState("");
  const [paymenttErr, setPaymenttErr] = useState("");

  const handleBuyOpen = () => {
    if (rowData?.type == "BUY" && paymentt != "") {
      setBuy(true);
    } else if (rowData?.type == "BUY" && paymentt == "") {
      setPaymenttErr("Please Select A Payment");
    } else if (rowData?.type == "SELL") {
      setBuy(true);
    }

  };
  const handleBuyClose = () => {
    setBuy(false);
    setTcErr("");
    setIRecieve(0);
  }
  const [openLoad, setOpenLoad] = useState(false);

  const [Sell, setSell] = useState(false);
  const handleSellOpen = () => setSell(true);
  const handleSellClose = () => setSell(false);
  //   const handleBuyOpen = () => {
  //     setBuy(true);
  //     setOpen(false);
  //   };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const [age, setAge] = useState("");
  const [items, setItems] = useState(10);
  const [page, setPage] = useState(1);
  const [iPay, setIPay] = useState(0);
  const [iRecieve, setIRecieve] = useState(0);
  const [qtyError, setQtyError] = useState("");
  const [tcErr, setTcErr] = useState("");
  const [payMethods, setPayMethods] = useState([]);
  const handleChange2 = (event) => {
    setAge(event.target.value);
  };
  const [payment, setPayment] = useState("");

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  const [selectedPrimaryTab, setSelectedPrimaryTab] = useState(0);
  const [selectedSubtab, setSelectedSubtab] = useState(
    Array(primaryTabData.length).fill('USDT', 0)
  );

  const handlePrimaryChange = (event, newValue) => {
    setSelectedPrimaryTab(newValue);
    // console.log(newValue, "newValuenewValue");
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [rowData, setRowData] = useState({});
  // console.log(rowData,"rowData");
  const [typeVal, setTypeVal] = useState('')


  const handlePopupOpen = (type, data) => {
    // console.log(data,"dataaa");

    try {
      setRowData(data);
      setTypeVal(type);

      // if (type == "sell") {
      //   setOpen2(true)
      // } else {
      setOpen(true)
      // }


    } catch (error) {
      console.log(error, "error opening popup")
    }

  };

  const handleSubtabChange = (index, newValue) => {
    const newSelectedSubtab = [...selectedSubtab];
    newSelectedSubtab[index] = newValue;
    setSelectedSubtab(newSelectedSubtab);
    // console.log(newSelectedSubtab, "newValue");
  };

  const navigate = useNavigate();
  const [p2p, setP2p] = useState([]);

  const getP2PList = async () => {

    try {
      setOpenLoad(true);
      var payload = {
        type: selectedPrimaryTab == 0 ? "SELL" : "BUY"
      };

      const url = `${consts.BackendUrl}/p2p/getp2p`;

      const response = await path.postCall({ url, payload });
      const result = await response.json();

      if (result?.success) {
        setP2p(result?.result);
      } else {
        setP2p(result?.result);
      }

    } catch (error) {
      console.log(error, "error");
    } finally {
      setOpenLoad(false);
    }
    // setPayment(10)
    // setAge(10)
  };

  const paginatedP2pList = p2p?.length > 0 ? p2p.slice((page - 1) * items, page * items) : [];

  useEffect(() => {
    getP2PList();
  }, [selectedPrimaryTab]);

  const handleP2pFilter = async () => {

    try {

      const payload = {
        type: selectedPrimaryTab == 0 ? "BUY" : "SELL",
        paymentMethod: payment,
        coinName: age
      }
      // console.log(payload, "Payment Method")
      const url = `${consts.BackendUrl}/p2p/getp2p`;

      const response = await path.postCall({ url, payload });
      const result = await response.json();

      if (result?.success) {
        setP2p(result?.result);
        toast.success("Filter Applied Successfully");
      } else {
        setP2p(result?.result);
      }

    } catch (error) {
      console.log(error, "Error Filtering P2p");
    }

  };

  const getP2pAssets = async () => {

    try {

      const url = `${consts.BackendUrl}/p2p/getp2passets`;

      const response = await path.getCallWithToken({ url });
      const result = await response.json();

      if (result?.success) {
        setP2pAsssets(result?.result);
      } 
      // else {
      //   toast.error(result?.message);
      // }

    } catch (error) {
      console.log(error, "Error Getting P2P Assets");
    }

  };

  const getPayMethods = async () => {

    try {

      const url = `${consts.BackendUrl}/p2p/getPaymentMethod`;

      const response = await path.getCallWithToken({ url });
      const result = await response.json();

      if (result?.success) {
        const uniquePayMethods = result?.result?.reduce((acc, current) => {
          if (!acc.find((item) => item.Type == current.Type)) {
            acc.push(current);
          }
          return acc;
        }, []);
        setPayMethods(uniquePayMethods);
      }

    } catch (error) {
      console.log(error, "Error Getting PayMethods");
    }

  };

  useEffect(() => {
    getP2pAssets();
    getPayMethods();
  }, []);

  const handlePay = async (val, data) => {

    try {
      setIPay(val);
      var qty = (Number(val) / Number(data?.price))
    
      if(Number(qty) > Number(data?.maxLimit)){
        setQtyError(`Max Limit Should Be ${data.maxLimit}`);
        setDis(true);
      } else if (Number(qty) < Number(data?.minLimit)) {
        setQtyError(`Min Limit Should Be ${data.minLimit}`);
        setDis(true);
      } else {
        setDis(false);
        setQtyError("");
        setIRecieve(qty);
      }

    } catch (error) {
      console.log(error, "error calculating pay get calculation");
    }

  };

  const handleQtyChange = async (val, data) => {

    try {
      setIRecieve(val);

      if (Number(val) > Number(data?.maxLimit)) {
        setDis(true);
        setQtyError(`Max Limit Shoud Be ${data?.maxLimit}`);
      } else if (Number(val) < Number(data?.minLimit)) {
        setDis(true);
        setQtyError(`Min Limit Shoud Be ${data?.minLimit}`);
      } else {
        const price = (Number(val) * Number(data?.price)).toFixed(4);
        setIPay(price);
        setDis(false);
        setQtyError("");
      }

      // if (data?.type == "SELL") {
      //   setQtyError("");
      //   setIRecieve(val);
      //   if (Number(val) > Number(data?.maxLimit)) {
      //     setDis(true);
      //     return setQtyError(`Max Limit Shoud Be ${data?.maxLimit}`);
      //   } else if (Number(val) < Number(data?.minLimit)) {
      //     setDis(true);
      //     return setQtyError(`Min Limit Shoud Be ${data?.minLimit}`);
      //   } else {
      //     setDis(false);
      //     const price = (Number(val) * Number(data?.price)).toFixed(4);
      //     setIPay(price);
      //   }

      // } else {

      //   setQtyError("");
      //   setIRecieve(val);

      //   const price = (Number(val) / Number(data?.price)).toFixed(4);
      //   console.log(typeof(price),price,"priceprice");
      //   if (Number(price) > Number(data?.maxLimit)) {
      //     setDis(true);
      //     return setQtyError(`Max Limit Shoud Be ${data?.maxLimit}`);
      //   } else if (Number(price) < Number(data?.minLimit)) {
      //     setDis(true);
      //     return setQtyError(`Min Limit Shoud Be ${data?.minLimit}`);
      //   } else {
      //     setDis(false);
      //     const qty = (Number(price) * Number(data?.price));
      //     console.log(qty,"price");
      //     setIPay(qty);
      //   }

      // }

    } catch (error) {
      console.log(error, "error calculating price");
    }

  };

  const handleTcCheck = async (e) => {

    try {
      setTcErr("");
      // console.log(e?.target?.checked,"targcheck");
      setIsTcChecked(e?.target?.checked);

    } catch (error) {
      console.log(error, "Error Checking TC Check");
    }

  }

  const handleNav = async () => {

    try {

      if (isTcChecked) {
        rowData.ipay = iPay
        rowData.iRecieve = iRecieve

        const url = `${consts.BackendUrl}/p2p/moveescrow`;

        var payload = {
          coin: rowData?.fromAsset,
          price: iPay,
          qty: iRecieve,
          type: rowData?.type,
          payType: paymentt,
          sellId: rowData?.user_id?._id,
          actualPrice: rowData?.price,
          type: rowData?.type,
          coinname: rowData?.toAsset,
          p2p_id: rowData?._id,
          availablePayment: rowData?.payments,
        };

        const response = await path.postCall({ url, payload });
        const result = await response.json();

        if (result?.success) {

          toast.success(result?.message);

          // const orderData = { ...result?.result, actualPrice: rowData?.price };

          setTimeout(() => {
            navigate("/orderdetails", { state: result?.result?.txId });
          }, 1000);
        }
        //  else {
        //   toast.error(result?.message);
        // }


      } else {
        setTcErr("Please Accept Terms & Conditions To Continue");
      }

    } catch (error) {
      console.log(error, "Error Checking");
    }

  };

  return (
    <div className="P2P">
      <ToastContainer />
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
      </Grid>
      <div className="p2p-full-cnt">
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="buy-sell-main-tab">
              <div className="buy-sell-tab-cnt">
                <Box>


                  <div className="buy-sell-text display-2">
                    <Tabs
                      value={selectedPrimaryTab}
                      onChange={handlePrimaryChange}
                    >
                      {primaryTabData.map((tab, index) => (
                        <Tab key={index} label={tab.label} {...a11yProps(index)} />
                      ))}
                    </Tabs>

                    <div className="display-2 gap main-payment-right">
                      <div
                        className="display-2 gap main-tab-right"
                        onClick={() => {
                          navigate("/paymentMethod");
                        }}
                      >
                        <PaymentIcon /> Payment Method
                      </div>
                      <div
                        className="display-2 gap main-tab-right"
                        onClick={() => {
                          navigate("/postnewad");
                        }}
                      >
                        <AddBoxIcon /> Post New Ad
                      </div>
                      <div
                        className="display-2 gap main-tab-right"
                        onClick={() => {
                          navigate("/myads");
                        }}
                      >
                        <FontDownloadIcon /> My Ads
                      </div>
                    </div>

                  </div>

                  {primaryTabData.map((tab, index) => (
                    <Box
                      key={index}
                      role="tabpanel"
                      hidden={selectedPrimaryTab !== index}
                      id={`primary-tabpanel-${index}`}
                      aria-labelledby={`primary-tab-${index}`}
                    >
                      {selectedPrimaryTab === index && (
                        <Box sx={{ pt: 1 }}>
                          <div className="buy-sell-text display-1">
                            <Tabs
                              className="usdt-btc"
                              value={selectedSubtab[index]}
                              onChange={(e, newValue) =>
                                handleSubtabChange(index, newValue)
                              }
                            >
                              {/* {primaryTabData.map((subtab, subIndex) => (
                                <Tab key={subIndex} label={subtab.sublabel} value={subtab.sublabel} {...a11yProps(index)} />
                              ))} */}
                            </Tabs>
                            <div className="display-2 table-top">
                              <div className="display-2 select-before buy-sell-main-tab2 ">
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  <InputLabel id="demo-select-small-label">Search Coin</InputLabel>
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={age}
                                    label="All Coin"
                                    placeholder="Search Coins"
                                    onChange={handleChange2}
                                    className="p2p-select"
                                  >
                                    {
                                      p2pAssets.length > 0 ? (p2pAssets.map((row, ind) => (
                                        <MenuItem value={row?.symbol}>{row?.symbol}</MenuItem>
                                      ))) :

                                        <MenuItem value={"USD"}>USD</MenuItem>

                                    }
                                    {/* <MenuItem value={""}>All Coin</MenuItem>
                                    <MenuItem value={20}>INR</MenuItem>
                                    <MenuItem value={30}>USD</MenuItem>
                                    <MenuItem value={"XRP"}>XRP</MenuItem> */}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  <InputLabel id="demo-select-small-label">Payment Method</InputLabel>
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={payment}
                                    label="Payment Method"
                                    onChange={handleChangePayment}
                                    className="p2p-select"
                                  >
                                    {/* <MenuItem value={"All Payments"} className="p2p-select">
                                      All Payments
                                    </MenuItem> */}
                                    <MenuItem value={"BANK"}>Net Banking</MenuItem>
                                    <MenuItem value={"GPAY"}>Gpay</MenuItem>
                                    <MenuItem value={"UPI"}>UPI</MenuItem>
                                  </Select>
                                </FormControl>
                                <div>
                                  <Button onClick={handleP2pFilter} variant="contained">
                                    <FilterAltIcon /> Search
                                  </Button>
                                </div>
                              </div>
                              <div className="display-2 gap cursor refre_sh" onClick={getP2PList} >
                                <RefreshIcon /> Refresh
                              </div>
                            </div>


                          </div>
                          <CustomTabPanel value={selectedPrimaryTab} index={selectedPrimaryTab == 0 ? 0 : 1}>
                            <div className="buy-sell-main-tab2 ">
                              <TableContainer>
                                <Table aria-label="simple table" stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left">Advertisers </TableCell>
                                      <TableCell align="center">Price</TableCell>
                                      {/* <TableCell align="center">Available | Limits</TableCell> */}
                                      <TableCell align="center">Volume | Limits</TableCell>
                                      <TableCell align="center">Payment Methods</TableCell>
                                      <TableCell align="center">Transaction Fees</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {/* {buyp2p.length > 0 ? ( */}
                                  {paginatedP2pList.length > 0 ? (
                                    <TableBody>
                                      {/* {buyp2p.map((row, ind) => ( */}
                                      {paginatedP2pList.map((row, ind) => (
                                        <TableRow key={ind}>
                                          <TableCell align="left">
                                            <div className="display-1 gap">
                                              <Avatar
                                                alt="Advertiser"
                                                src={row?.user_id?.profilePicture ? row?.user_id?.profilePicture : "/static/images/avatar/1.jpg"}
                                              />
                                              <div className="table-profile-text">
                                                <div className="usr">{row?.user_id?.name ? row?.user_id?.name : " - "}</div>
                                                {/* <div className="order">{row?.order}</div> */}
                                                <div
                                                  className={
                                                    row.live === "online" ? "green order" : "red order"
                                                  }
                                                >
                                                  {row.live}
                                                </div>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div className="p2p-table-price">{row?.price}</div>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div className="display-2 aval">
                                              {/* Available: <span className="p2p-table-price">98.58$</span> */}
                                              Volume: <span className="p2p-table-price">{row?.volume}</span>
                                            </div>
                                            <div className="display-2 aval">
                                              {/* Limits: <span className="p2p-table-price">98.58$</span> */}
                                              Limits: <span className="p2p-table-price">{`${row?.minLimit} - ${row?.maxLimit}`}</span>
                                            </div>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div className="display-2 gap aval">
                                              {
                                                row?.payments.includes("BANK") && (
                                                  <Chip icon={<AccountBalanceIcon />} label="Bank" />
                                                )
                                              }
                                              {
                                                row?.payments.includes("UPI") && (
                                                  <Chip icon={<AccountBalanceWalletIcon />} label="UPI" />
                                                )
                                              }
                                              {
                                                row?.payments.includes("GPAY") && (
                                                  <Chip icon={<GoogleIcon />} label="GPAY" />
                                                )
                                              }
                                            </div>
                                          </TableCell>
                                          <TableCell align="center">
                                            {selectedPrimaryTab == 1 ? (
                                              <div className="buyusdt">
                                                {/* <Button className="bg-red" onClick={handleOpen2}>
                                                  Sell USDT
                                                </Button> */}
                                                <Button className="bg-red" onClick={() => handlePopupOpen("sell", row)}>
                                                  Sell
                                                </Button>
                                              </div>
                                            ) : <div className="buyusdt">
                                              <Button className="bg-green" onClick={() => handlePopupOpen("buy", row)}>
                                                Buy
                                              </Button>
                                            </div>}

                                          </TableCell>

                                          <Modal
                                            aria-labelledby="transition-modal-title"
                                            aria-describedby="transition-modal-description"
                                            open={open}
                                            closeAfterTransition
                                            slots={{ backdrop: Backdrop }}
                                            slotProps={{
                                              backdrop: {
                                                timeout: 1000,
                                              },
                                            }}
                                          >
                                            <Fade in={open}>
                                              <Box sx={style}>
                                                <div
                                                  className="close-menu text-end cursor"
                                                  onClick={() => {
                                                    handleClose();
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </div>
                                                <Grid container>
                                                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                                    <div className="display-1 gap">
                                                      <div className="pop-avatar">
                                                        <Avatar
                                                          alt="Remy Sharp"
                                                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                                                          sx={{ width: 100, height: 100 }}
                                                        />
                                                      </div>
                                                      <div className="table-prfile-text pop">
                                                        <div className="usr">Sankar</div>
                                                        <div className="order">
                                                          13 Orders | 69.23% completion
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="display-1 time-details">
                                                      <div>
                                                        15 Minutes
                                                        <div className="order">payment Time Limit</div>
                                                      </div>
                                                      <div>
                                                        5.81 Minutes
                                                        <div className="order">Avg Release Time</div>
                                                      </div>
                                                      <div>
                                                        8.97584 USDT
                                                        <div className="order">Available</div>
                                                      </div>
                                                    </div>
                                                    <div className="display-2 gap aval2">
                                                      <Chip
                                                        icon={<AccountBalanceIcon />}
                                                        label="Bank"
                                                      />
                                                      <Chip
                                                        icon={<AccountBalanceWalletIcon />}
                                                        label="UPI"
                                                      />
                                                    </div>
                                                    <div className="pop-desc-main">
                                                      <div className="pop-desc">
                                                        Advertiser's Terms{" "}
                                                        <span className="red">
                                                          (Please read carefully)
                                                        </span>
                                                      </div>
                                                      <div className="pop-desc">
                                                        First Read condition.
                                                      </div>
                                                      <div className="pop-desc">
                                                        All payment options are available.
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                                    <div className="pop-right-main">
                                                      <div className="price">
                                                        Price: <span className="green">98 INR</span>
                                                      </div>
                                                      <div className="will-pay">
                                                        <div className="will-pay-text">I Will Pay</div>
                                                        <div className="pop-right-input">
                                                          <FormControl
                                                            sx={{ m: 1, width: "100%" }}
                                                            variant="outlined"
                                                          >
                                                            <OutlinedInput
                                                              id="outlined-adornment-weight"
                                                              endAdornment={
                                                                <InputAdornment position="end">
                                                                  INR
                                                                </InputAdornment>
                                                              }
                                                              aria-describedby="outlined-weight-helper-text"
                                                              inputProps={{
                                                                "aria-label": "weight",
                                                              }}
                                                              placeholder="0.00"
                                                            />
                                                          </FormControl>
                                                        </div>
                                                      </div>
                                                      <div className="will-pay">
                                                        <div className="will-pay-text">
                                                          I Will recieve
                                                        </div>
                                                        <div className="pop-right-input">
                                                          <FormControl
                                                            sx={{ m: 1, width: "100%" }}
                                                            variant="outlined"
                                                          >
                                                            <OutlinedInput
                                                              id="outlined-adornment-weight"
                                                              endAdornment={
                                                                <InputAdornment position="end">
                                                                  USDT
                                                                </InputAdornment>
                                                              }
                                                              aria-describedby="outlined-weight-helper-text"
                                                              inputProps={{
                                                                "aria-label": "weight",
                                                              }}
                                                              placeholder="1-5"
                                                            />
                                                          </FormControl>
                                                        </div>
                                                      </div>
                                                      <div className="display-3 gap">
                                                        <Button
                                                          onClick={handleClose}
                                                          className="cancel-btn"
                                                        >
                                                          Cancel
                                                        </Button>
                                                        <Button
                                                          variant="contained"
                                                          className="bg-green"
                                                          onClick={handleBuyOpen}
                                                        >
                                                          Buy
                                                        </Button>
                                                        <Modal
                                                          open={Buy}
                                                          onClose={handleBuyClose}
                                                          aria-labelledby="modal-modal-title"
                                                          aria-describedby="modal-modal-description"
                                                        >
                                                          <Box className="id-popup p2p-pop terms-modal">
                                                            <div
                                                              className="close-menu text-end cursor display-2"
                                                              onClick={() => {
                                                                handleBuyClose();
                                                              }}
                                                            >
                                                              <Typography
                                                                id="modal-modal-title"
                                                                variant="h6"
                                                                component="h2"
                                                              >
                                                                P2P Terms
                                                              </Typography>
                                                              <CloseIcon />
                                                            </div>

                                                            <div className="p2p-terms">
                                                              <Typography
                                                                id="modal-modal-description"
                                                                sx={{ mt: 2 }}
                                                              >
                                                                Make sure to scroll through and read the
                                                                Kaavi P2P Additional Verification
                                                                Requirements & Guidelines before agreeing.
                                                              </Typography>
                                                              <p>If you are a P2P taker:</p>
                                                              <List className="terms-lines">
                                                                <ListItem disablePadding>
                                                                  You acknowledge and agree that Kaavi is
                                                                  not a party singly or together with your
                                                                  counterparty to the P2P transaction. You
                                                                  also acknowledge and agree that you are
                                                                  sharing your personal information for
                                                                  identity verification purposes with your
                                                                  counterparty and not with Kaavi.
                                                                </ListItem>
                                                                <ListItem disablePadding>
                                                                  Your conduct of a P2P transaction with a
                                                                  counterparty will be deemed your
                                                                  agreement to provide required personal
                                                                  information for identity verification
                                                                  purposes to that counterparty in
                                                                  accordance with the applicable laws and
                                                                  regulations. Such information may be
                                                                  used to verify your identity, identify
                                                                  traces of money laundering, terrorist
                                                                  financing, fraud, and other financial
                                                                  crimes or for other lawful purposes.
                                                                </ListItem>
                                                                <ListItem disablePadding>
                                                                  In addition to providing such
                                                                  information, you agree to allow your
                                                                  counterparty to keep a record of that
                                                                  information in compliance with the
                                                                  applicable laws and regulations. You
                                                                  also authorize the counterparty to
                                                                  conduct necessary investigations
                                                                  directly or through a third party to
                                                                  verify your identity or protect you
                                                                  and/or the counterparty from financial
                                                                  crimes, such as fraud or other financial
                                                                  crimes, and to take necessary actions
                                                                  based on the results of such
                                                                  investigations, including but not
                                                                  limited to, sharing of your data and/or
                                                                  rejecting to conclude a P2P transaction
                                                                  with you.
                                                                </ListItem>
                                                                <ListItem disablePadding>
                                                                  Disclaimer: Kaavi does not have any kind
                                                                  of involvement in your P2P transaction
                                                                  with your counterparty or in your
                                                                  collecting or sharing of information for
                                                                  identity verification purposes
                                                                  whatsoever. Thus, Kaavi shall not be
                                                                  held responsible with regards to how
                                                                  your personal data is used by the
                                                                  counterparty.
                                                                </ListItem>
                                                              </List>
                                                              <div className="checkbox display-1">
                                                                <Checkbox />
                                                                <p>I have read and agree to the terms.</p>
                                                              </div>
                                                              <div className="cfrm-btn text-center">
                                                                <Button
                                                                  onClick={() => {
                                                                    navigate("/orderdetails");
                                                                  }}
                                                                >
                                                                  Confirm
                                                                </Button>
                                                              </div>
                                                            </div>
                                                          </Box>
                                                        </Modal>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Fade>
                                          </Modal>
                                          <Modal
                                            aria-labelledby="transition-modal-title"
                                            aria-describedby="transition-modal-description"
                                            open={open2}
                                            closeAfterTransition
                                            slots={{ backdrop: Backdrop }}
                                            slotProps={{
                                              backdrop: {
                                                timeout: 1000,
                                              },
                                            }}
                                          >
                                            <Fade in={open2}>
                                              <Box sx={style}>
                                                {/* <div className="close-icon cursor">
                <HighlightOffIcon onClick={handleClose2} />
              </div> */}
                                                <div
                                                  className="close-menu text-end cursor"
                                                  onClick={() => {
                                                    handleClose2();
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </div>
                                                <Grid container>
                                                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                                    <div className="display-1 gap">
                                                      <div className="pop-avatar">
                                                        <Avatar
                                                          alt="Remy Sharp"
                                                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                                                          sx={{ width: 100, height: 100 }}
                                                        />
                                                      </div>
                                                      <div className="table-prfile-text pop">
                                                        <div className="usr">Sankar</div>
                                                        <div className="order">
                                                          13 Orders | 69.23% completion
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="display-1 time-details">
                                                      <div>
                                                        15 Minutes
                                                        <div className="order">payment Time Limit</div>
                                                      </div>
                                                      <div>
                                                        5.81 Minutes
                                                        <div className="order">Avg Release Time</div>
                                                      </div>
                                                      <div>
                                                        8.97584 USDT
                                                        <div className="order">Available</div>
                                                      </div>
                                                    </div>
                                                    <div className="display-2 gap aval2">
                                                      <Chip
                                                        icon={<AccountBalanceIcon />}
                                                        label="Bank"
                                                      />
                                                      <Chip
                                                        icon={<AccountBalanceWalletIcon />}
                                                        label="UPI"
                                                      />
                                                    </div>
                                                    <div className="pop-desc-main">
                                                      <div className="pop-desc">
                                                        Advertiser's Terms{" "}
                                                        <span className="red">
                                                          (Please read carefully)
                                                        </span>
                                                      </div>
                                                      <div className="pop-desc">
                                                        First Read condition.
                                                      </div>
                                                      <div className="pop-desc">
                                                        All payment options are available.
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                                    <div className="pop-right-main">
                                                      <div className="price">
                                                        Price: <span className="green">98 INR</span>
                                                      </div>
                                                      <div className="will-pay">
                                                        <div className="will-pay-text">I Will Pay</div>
                                                        <div className="pop-right-input">
                                                          <FormControl
                                                            sx={{ m: 1, width: "100%" }}
                                                            variant="outlined"
                                                          >
                                                            <OutlinedInput
                                                              id="outlined-adornment-weight"
                                                              endAdornment={
                                                                <InputAdornment position="end">
                                                                  INR
                                                                </InputAdornment>
                                                              }
                                                              aria-describedby="outlined-weight-helper-text"
                                                              inputProps={{
                                                                "aria-label": "weight",
                                                              }}
                                                              placeholder="0.00"
                                                            />
                                                          </FormControl>
                                                        </div>
                                                      </div>
                                                      <div className="will-pay">
                                                        <div className="will-pay-text">
                                                          I Will recieve
                                                        </div>
                                                        <div className="pop-right-input">
                                                          <FormControl
                                                            sx={{ m: 1, width: "100%" }}
                                                            variant="outlined"
                                                          >
                                                            <OutlinedInput
                                                              id="outlined-adornment-weight"
                                                              endAdornment={
                                                                <InputAdornment position="end">
                                                                  USDT
                                                                </InputAdornment>
                                                              }
                                                              aria-describedby="outlined-weight-helper-text"
                                                              inputProps={{
                                                                "aria-label": "weight",
                                                              }}
                                                              placeholder="1-5"
                                                            />
                                                          </FormControl>
                                                        </div>
                                                      </div>
                                                      <div className="display-3 gap">
                                                        <Button
                                                          onClick={handleClose2}
                                                          className="cancel-btn"
                                                        >
                                                          Cancel
                                                        </Button>
                                                        <Button
                                                          variant="contained"
                                                          className="bg-red"
                                                          onClick={handleSellOpen}
                                                        >
                                                          Sell
                                                        </Button>
                                                        <Modal
                                                          open={Sell}
                                                          onClose={handleSellClose}
                                                          aria-labelledby="modal-modal-title"
                                                          aria-describedby="modal-modal-description"
                                                        >
                                                          <Box className="id-popup p2p-pop terms-modal">
                                                            <div
                                                              className="close-menu text-end cursor"
                                                              onClick={() => {
                                                                handleSellClose();
                                                              }}
                                                            >
                                                              <CloseIcon />
                                                            </div>
                                                            <Typography
                                                              id="modal-modal-title"
                                                              variant="h6"
                                                              component="h2"
                                                            >
                                                              P2P Terms
                                                            </Typography>
                                                            <Typography
                                                              id="modal-modal-description"
                                                              sx={{ mt: 2 }}
                                                            >
                                                              Make sure to scroll through and read the
                                                              Kaavi P2P Additional Verification
                                                              Requirements & Guidelines before agreeing.
                                                            </Typography>
                                                            <p>If you are a P2P taker:</p>
                                                            <List className="terms-lines">
                                                              <ListItem disablePadding>
                                                                You acknowledge and agree that Kaavi is
                                                                not a party singly or together with your
                                                                counterparty to the P2P transaction. You
                                                                also acknowledge and agree that you are
                                                                sharing your personal information for
                                                                identity verification purposes with your
                                                                counterparty and not with Kaavi.
                                                              </ListItem>
                                                              <ListItem disablePadding>
                                                                Your conduct of a P2P transaction with a
                                                                counterparty will be deemed your
                                                                agreement to provide required personal
                                                                information for identity verification
                                                                purposes to that counterparty in
                                                                accordance with the applicable laws and
                                                                regulations. Such information may be
                                                                used to verify your identity, identify
                                                                traces of money laundering, terrorist
                                                                financing, fraud, and other financial
                                                                crimes or for other lawful purposes.
                                                              </ListItem>
                                                              <ListItem disablePadding>
                                                                In addition to providing such
                                                                information, you agree to allow your
                                                                counterparty to keep a record of that
                                                                information in compliance with the
                                                                applicable laws and regulations. You
                                                                also authorize the counterparty to
                                                                conduct necessary investigations
                                                                directly or through a third party to
                                                                verify your identity or protect you
                                                                and/or the counterparty from financial
                                                                crimes, such as fraud or other financial
                                                                crimes, and to take necessary actions
                                                                based on the results of such
                                                                investigations, including but not
                                                                limited to, sharing of your data and/or
                                                                rejecting to conclude a P2P transaction
                                                                with you.
                                                              </ListItem>
                                                              <ListItem disablePadding>
                                                                Disclaimer: Kaavi does not have any kind
                                                                of involvement in your P2P transaction
                                                                with your counterparty or in your
                                                                collecting or sharing of information for
                                                                identity verification purposes
                                                                whatsoever. Thus, Kaavi shall not be
                                                                held responsible with regards to how
                                                                your personal data is used by the
                                                                counterparty.
                                                              </ListItem>
                                                            </List>
                                                            <div className="checkbox display-1">
                                                              <Checkbox />
                                                              <p>I have read and agree to the terms.</p>
                                                            </div>
                                                            <div className="cfrm-btn text-center">
                                                              <Button
                                                                onClick={() => {
                                                                  navigate("/orderdetails");
                                                                }}
                                                              >
                                                                Confirm
                                                              </Button>
                                                            </div>
                                                          </Box>
                                                        </Modal>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Fade>
                                          </Modal>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  ) : (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell align="center" colSpan={5}>
                                          <div className="nodata">
                                            {/* <img src="/static/images/no-data.png" alt="No data" /> */}
                                            No Record Found
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  )}
                                </Table>
                              </TableContainer>
                              <div className="pagination paddin-10 mt-20">
                                <Pagination count={Math.ceil(p2p?.length / items)} page={page} onChange={handlePageChange} shape="rounded" />
                              </div>
                            </div>
                          </CustomTabPanel>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </div>
            </div>
          </Grid>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 1000,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  className="close-menu text-end cursor"
                >
                  <CloseIcon onClick={() => {
                    handleClose();
                  }} />
                </div>
                <Grid container>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="display-1 gap">
                      <div className="pop-avatar">
                        <Avatar
                          alt={rowData?.user_id?.name}
                          src={rowData?.user_id?.profilePicture}
                          // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                          sx={{ width: 100, height: 100 }}
                        />
                      </div>
                      <div className="table-prfile-text pop">
                        <div className="usr">{rowData?.user_id?.name}</div>
                        {/* <div className="order">
                          13 Orders | 69.23% completion
                        </div> */}
                      </div>
                    </div>
                    <div className="display-1 time-details">
                      {/* <div>
                        15 Minutes
                        <div className="order">payment Time Limit</div>
                      </div> */}
                      {/* <div>
                        5.81 Minutes
                        <div className="order">Avg Release Time</div>
                      </div> */}
                      <div>
                        {/* 8.97584 USDT */}
                        {rowData?.volume}
                        <div className="order">Available</div>
                      </div>
                    </div>
                    <div className="display-2 gap aval2">
                      {/* <Chip icon={<AccountBalanceIcon />} label="Bank" />
                      <Chip icon={<AccountBalanceWalletIcon />} label="UPI" /> */}

                      {
                        rowData?.payments ? rowData?.payments.includes("BANK") && (
                          <Chip icon={<AccountBalanceIcon />} label="Bank" />
                        ) : ""
                      }
                      {
                        rowData?.payments ? rowData?.payments.includes("UPI") && (
                          <Chip icon={<AccountBalanceWalletIcon />} label="UPI" />
                        ) : ""
                      }
                      {
                        rowData?.payments ? rowData?.payments.includes("GPAY") && (
                          <Chip icon={<GoogleIcon />} label="GPAY" />
                        ) : ""
                      }

                    </div>
                    <div className="pop-desc-main">
                      <div className="pop-desc">
                        Advertiser's Terms{" "}
                        <span className="red">(Please read carefully)</span>
                      </div>
                      <div className="pop-desc">First Read condition.</div>
                      <div className="pop-desc">
                        All The Above Payment Options Are Available.
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="pop-right-main">
                      <div className="price" style={{ display: "flex", justifyContent: "space-between" }}>
                        {/* Price: <span className="green">98 INR</span> */}
                        Price: <span className="green">{`${rowData?.price} ${rowData?.toAsset}`}</span>
                        Limit: <span className="green">{`${rowData?.minLimit} - ${rowData?.maxLimit}`}</span>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">{rowData?.type == "SELL" ? "I Will Pay" : "I Will Get"}</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            onChange={(e) => handlePay(e?.target?.value, rowData)}
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              type="number"
                              value={iPay}
                              endAdornment={
                                <InputAdornment position="end">
                                  {/* INR */}
                                  {rowData?.toAsset}
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="0.00"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">{rowData?.type == "SELL" ? "I Will Get" : "I Will Pay"}</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            onChange={(e) => handleQtyChange(e?.target?.value, rowData)}
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              value={iRecieve}
                              endAdornment={
                                <InputAdornment position="end">
                                  {/* {` ${rowData?.minLimit} - ${rowData?.maxLimit} USDT`} */}
                                  {` ${rowData?.fromAsset}`}
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="1-5"
                            // disabled
                            // sx={{
                            //   "& .MuiOutlinedInput-notchedOutline": {
                            //     cursor: "not-allowed", // Change cursor to not-allowed for the border
                            //   },
                            //   "& input": {
                            //     cursor: "not-allowed", // Change cursor to not-allowed for the text area
                            //   },
                            // }}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div> {qtyError !== "" ? <p style={{ color: "red", textAlign: "center" }}>{qtyError}</p> : ""} </div>

                      {
                        rowData?.type === "BUY" && (
                          <div className="will-pay">
                            <div className="will-pay-text">Select payment</div>
                            <div className="pop-right-input">
                              <FormControl fullWidth sx={{ mb: 3 }}>
                                <Select
                                  className="sel_box"
                                  onChange={(e) => { setPaymentt(e?.target?.value); setPaymenttErr("") }}
                                  sx={{
                                    padding: "0px !important",
                                    fieldset: { border: "1px solid #d7d7d7 !important", borderRadius: "6px" },
                                    color: "#000",
                                    svg: { fill: "#000" },
                                  }}
                                // defaultValue={"Bank"}
                                >
                                  {payMethods.length > 0 ? (
                                    payMethods.map((item, index) => (
                                      <MenuItem key={index} value={item?.Type}>
                                        {item?.Type}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="">No Payment Available</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                            <div> {paymenttErr != "" ? <p style={{ color: "red", textAlign: "center" }}>{paymenttErr}</p> : null} </div>
                          </div>
                        )
                      }

                      <div className="display-3 gap">
                        <Button onClick={handleClose} className="cancel-btn">
                          Cancel
                        </Button>
                        {typeVal == 'buy' ? <Button
                          variant="contained"
                          className="bg-green gren_bt"
                          onClick={handleBuyOpen}
                          disabled={dis}
                        >
                          Buy
                        </Button> :
                          <Button
                            variant="contained"
                            className="bg-red"
                            onClick={handleBuyOpen}
                            disabled={dis}
                          >
                            sell
                          </Button>
                        }
                        <Modal
                          open={Buy}
                          onClose={handleBuyClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="id-popup p2p-pop terms-modal">
                            <div
                              className="close-menu text-end cursor"
                              onClick={() => {
                                handleBuyClose();
                              }}
                            >
                              <CloseIcon />
                            </div>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              P2P Terms
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              Make sure to scroll through and read the Kaavi P2P
                              Additional Verification Requirements & Guidelines
                              before agreeing.
                            </Typography>
                            <p>If you are a P2P taker:</p>
                            <List className="terms-lines">
                              <ListItem disablePadding>
                                You acknowledge and agree that Kaavi is not a
                                party singly or together with your counterparty
                                to the P2P transaction. You also acknowledge and
                                agree that you are sharing your personal
                                information for identity verification purposes
                                with your counterparty and not with Kaavi.
                              </ListItem>
                              <ListItem disablePadding>
                                Your conduct of a P2P transaction with a
                                counterparty will be deemed your agreement to
                                provide required personal information for
                                identity verification purposes to that
                                counterparty in accordance with the applicable
                                laws and regulations. Such information may be
                                used to verify your identity, identify traces of
                                money laundering, terrorist financing, fraud,
                                and other financial crimes or for other lawful
                                purposes.
                              </ListItem>
                              <ListItem disablePadding>
                                In addition to providing such information, you
                                agree to allow your counterparty to keep a
                                record of that information in compliance with
                                the applicable laws and regulations. You also
                                authorize the counterparty to conduct necessary
                                investigations directly or through a third party
                                to verify your identity or protect you and/or
                                the counterparty from financial crimes, such as
                                fraud or other financial crimes, and to take
                                necessary actions based on the results of such
                                investigations, including but not limited to,
                                sharing of your data and/or rejecting to
                                conclude a P2P transaction with you.
                              </ListItem>
                              <ListItem disablePadding>
                                Disclaimer: Kaavi does not have any kind of
                                involvement in your P2P transaction with your
                                counterparty or in your collecting or sharing of
                                information for identity verification purposes
                                whatsoever. Thus, Kaavi shall not be held
                                responsible with regards to how your personal
                                data is used by the counterparty.
                              </ListItem>
                            </List>
                            <div className="checkbox display-1">
                              <Checkbox onChange={handleTcCheck} />
                              <p>I have read and agree to the terms.</p>
                            </div>

                            <div> {tcErr != "" ? <p style={{ color: "red", textAlign: "center" }}>{tcErr}</p> : ""} </div>

                            <div className="cfrm-btn text-center">
                              <Button
                                // onClick={() => {
                                //   navigate("/orderdetails");
                                // }}
                                onClick={() => {
                                  handleNav();
                                }}
                              >
                                Confirm
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open2}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 1000,
              },
            }}
          >
            <Fade in={open2}>
              <Box sx={style}>
                {/* <div className="close-icon cursor">
                <HighlightOffIcon onClick={handleClose2} />
              </div> */}
                <div
                  className="close-menu text-end cursor"
                  onClick={() => {
                    handleClose2();
                  }}
                >
                  <CloseIcon />
                </div>
                <Grid container>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="display-1 gap">
                      <div className="pop-avatar">
                        <Avatar
                          alt="Remy Sharp"
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                          sx={{ width: 100, height: 100 }}
                        />
                      </div>
                      <div className="table-prfile-text pop">
                        <div className="usr">Sankar</div>
                        <div className="order">
                          13 Orders | 69.23% completion
                        </div>
                      </div>
                    </div>
                    <div className="display-1 time-details">
                      <div>
                        15 Minutes
                        <div className="order">payment Time Limit</div>
                      </div>
                      <div>
                        5.81 Minutes
                        <div className="order">Avg Release Time</div>
                      </div>
                      <div>
                        8.97584 USDT
                        <div className="order">Available</div>
                      </div>
                    </div>
                    <div className="display-2 gap aval2">
                      <Chip icon={<AccountBalanceIcon />} label="Bank" />
                      <Chip icon={<AccountBalanceWalletIcon />} label="UPI" />
                    </div>
                    <div className="pop-desc-main">
                      <div className="pop-desc">
                        Advertiser's Terms{" "}
                        <span className="red">(Please read carefully)</span>
                      </div>
                      <div className="pop-desc">First Read condition.</div>
                      <div className="pop-desc">
                        All payment options are available.
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="pop-right-main">
                      <div className="price">
                        Price: <span className="green">98 INR</span>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">I Will Pay</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={
                                <InputAdornment position="end">
                                  INR
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="0.00"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">I Will recieve</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={
                                <InputAdornment position="end">
                                  USDT
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="1-5"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">Select payment</div>
                        <div className="pop-right-input">
                          <FormControl fullWidth sx={{ mb: 3 }}>
                            {/* <InputLabel>To</InputLabel> */}
                            <Select
                              className='sel_box'
                              sx={{ padding: "0px !important", fieldset: { border: "1px solid #d7d7d7 !important", borderRadius: "6px" }, color: '#000', svg: { fill: '#000' } }}
                              defaultValue={"Bank"}
                            >


                              <MenuItem value="Bank">Bank</MenuItem>
                              <MenuItem value="upi">upi</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="display-3 gap">
                        <Button onClick={handleClose2} className="cancel-btn">
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="bg-red red_bt"
                          onClick={handleSellOpen}
                        >
                          Sell
                        </Button>
                        <Modal
                          open={Sell}
                          onClose={handleSellClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="id-popup p2p-pop terms-modal">
                            <div
                              className="close-menu text-end cursor"
                              onClick={() => {
                                handleSellClose();
                              }}
                            >
                              <CloseIcon />
                            </div>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              P2P Terms
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              Make sure to scroll through and read the Kaavi P2P
                              Additional Verification Requirements & Guidelines
                              before agreeing.
                            </Typography>
                            <p>If you are a P2P taker:</p>
                            <List className="terms-lines">
                              <ListItem disablePadding>
                                You acknowledge and agree that Kaavi is not a
                                party singly or together with your counterparty
                                to the P2P transaction. You also acknowledge and
                                agree that you are sharing your personal
                                information for identity verification purposes
                                with your counterparty and not with Kaavi.
                              </ListItem>
                              <ListItem disablePadding>
                                Your conduct of a P2P transaction with a
                                counterparty will be deemed your agreement to
                                provide required personal information for
                                identity verification purposes to that
                                counterparty in accordance with the applicable
                                laws and regulations. Such information may be
                                used to verify your identity, identify traces of
                                money laundering, terrorist financing, fraud,
                                and other financial crimes or for other lawful
                                purposes.
                              </ListItem>
                              <ListItem disablePadding>
                                In addition to providing such information, you
                                agree to allow your counterparty to keep a
                                record of that information in compliance with
                                the applicable laws and regulations. You also
                                authorize the counterparty to conduct necessary
                                investigations directly or through a third party
                                to verify your identity or protect you and/or
                                the counterparty from financial crimes, such as
                                fraud or other financial crimes, and to take
                                necessary actions based on the results of such
                                investigations, including but not limited to,
                                sharing of your data and/or rejecting to
                                conclude a P2P transaction with you.
                              </ListItem>
                              <ListItem disablePadding>
                                Disclaimer: Kaavi does not have any kind of
                                involvement in your P2P transaction with your
                                counterparty or in your collecting or sharing of
                                information for identity verification purposes
                                whatsoever. Thus, Kaavi shall not be held
                                responsible with regards to how your personal
                                data is used by the counterparty.
                              </ListItem>
                            </List>
                            <div className="checkbox display-1">
                              <Checkbox />
                              <p>I have read and agree to the terms.</p>
                            </div>
                            <div className="cfrm-btn text-center">
                              <Button
                                onClick={() => {
                                  navigate("/orderdetails");
                                }}
                              >
                                Confirm
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Modal>
        </Grid>
      </div>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={openLoad}
      // onClick={handleCloseLoad}
      >

        <div class="loading-bar">
          <img src={loading} alt="loading" width="80px" height="80px" style={{ marginTop: '15px' }} />
        </div>

      </Backdrop>
    </div>
  );
}

export default P2P;
