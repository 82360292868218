
import './Scss/main.scss';
import './Css/App.css'
import Darkmode from './Components/DarkMode/Darkmode';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Components/Authentication/Login'
import Register from './Components/Authentication/Register'
import Verify from './Components/Authentication/Verify'
import PasswordRequest from './Components/Authentication/Forgot'
// import Success from './Components/Authentication/Success'
import Header from './Components/Header/Header';
import Trade from './Components/TradePage/Trade';
import Profile from './Components/Profile/Profile';
import ProfileNew from './Components/ProfileNew/ProfileNew';
import ProfileVerify from './Components/ProfileNew/ProfileVerify';
import Payment from './Components/Payment/Payment';
import UPIAccount from './Components/Payment/UPIAccount';
import BankAccount from './Components/Payment/BankAccount';
import Support from './Components/Support/Support';
import Earning from './Components/Earning/Earning';
import Wallet from './Components/Wallet/Wallet';
import Deposit from './Components/Wallet/Deposit';
import Withdraw from './Components/Wallet/Withdraw';
import Staking from './Components/Staking/Staking';
import Subscribe from './Components/Staking/Subscribe';
import Market from './Components/Market/Market';
import Referral from './Components/Referral/Referral';
import P2P from './Components/P2P/P2P';
import PostNewAd from './Components/P2P/PostNewAd';
import PaymentMethod from './Components/P2P/PaymentMethod';
import AddPayment from './Components/P2P/AddPayment';
import MyAds from './Components/P2P/MyAds';
import OrderDetail from './Components/P2P/OrderDetail';
import TradeHistory from './Components/TradeHistory/TradeHistory';
// import RegisterSuccess from './Components/Authentication/RegisterSuccess';
import Swap from './Components/Swap/Swap';
import Kyc from './Components/KycVerify/Kyc';
import Twofaverify from './Components/Twofaverify/Twofaverify';
import Underconstruct from './Components/ConstructionPage/Underconstruct';
import UnderMaintain from './Components/ConstructionPage/UnderMaintain';
import Page404 from './Components/ConstructionPage/Page404';
import Page502 from './Components/ConstructionPage/Page502';
import Resend from './Components/Authentication/Resend';
import Forgot from './Components/Authentication/Forgot';
import Reset from './Components/Authentication/Reset';
import PrivateRoute from './PrivateRoute';
import Home from './Components/Home/home';
import Copytrade from './Components/CopyTrade/Copytrade';
import Verify2fa from './Components/Authentication/TwoFa';
import { addClickSoundListener, removeClickSoundListener } from './Components/Sound/Sound';
import { useEffect } from 'react';
import CopySettings from './Components/CopySettings/CopySettings';
import Chart from './Components/Chart/Chart'
import { useLocation } from 'react-router-dom';
import Copytradecopy from './Components/CopyTrade/Copytrade-copy';


function App() {

  const location = useLocation();

  const cPath = location.pathname;

  console.log(cPath, 'pathname');

  useEffect(() => {
    // Add the click sound listener on mount
    addClickSoundListener();

    // Clean up by removing the listener on unmount
    return () => {
      removeClickSoundListener();
    };
  }, []);
  return (
    <div className="App" style={{ marginTop: [!['/login', '/register', '/resend', '/verify', '/2fa', '/chart', '/forgot', '/reset'].includes(cPath) ? '70px' : '0px'] }}>

      <Routes>
        <Route exact path="/darkmode" element={<Darkmode />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/resend" element={<Resend />} />
        <Route exact path="/verify" element={<Verify />} />
        <Route exact path="/2fa" element={<Verify2fa />} />

        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/reset" element={<Reset />} />
        {/* <Route exact path="/success" element={<Success />} /> */}
        {/* <Route exact path="/register-success" element={<RegisterSuccess />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/header" element={<Header />} />
        <Route exact path="/trade/:id" element={<Trade />} />
        <Route exact path="/margin/:id" element={<Trade />} />
        <Route exact path="/future/:id" element={<Trade />} />
        <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route exact path="/profilenew" element={<ProfileNew />} />
        <Route exact path="/profileverify" element={<ProfileVerify />} />
        <Route exact path="/payment-option" element={<Payment />} />
        <Route exact path="/newupi-account" element={<UPIAccount />} />
        <Route exact path="/newbank-account" element={<BankAccount />} />
        <Route exact path="/support" element={<Support />} />
        <Route exact path="/earning" element={<Earning />} />
        <Route exact path="/wallet" element={<Wallet />} />
        <Route exact path="/deposit" element={<Deposit />} />
        <Route exact path="/withdraw" element={<Withdraw />} />
        <Route exact path="/staking" element={<Staking />} />
        <Route exact path="/subscribe" element={<Subscribe />} />
        <Route exact path="/market" element={<Market />} />
        <Route exact path="/referralinformation" element={<Referral />} />
        <Route exact path="/p2p" element={<P2P />} />
        <Route exact path="/postnewad" element={<PostNewAd />} />
        <Route exact path="/paymentMethod" element={<PaymentMethod />} />
        <Route exact path="/addpayment" element={<AddPayment />} />
        <Route exact path="/myads" element={<MyAds />} />
        <Route exact path="/orderdetails" element={<OrderDetail />} />
        <Route exact path="/trade-history" element={<PrivateRoute> <TradeHistory /> </PrivateRoute>} />
        <Route exact path="/swap" element={<Swap />} />
        <Route exact path="/kyc" element={<PrivateRoute><Kyc /></PrivateRoute>} />
        <Route exact path="/twofaverify" element={<Twofaverify />} />
        <Route exact path="/underconstruct" element={<Underconstruct />} />
        <Route exact path="/undermaintain" element={<UnderMaintain />} />
        <Route exact path="/page404" element={<Page404 />} />
        <Route exact path="/page502" element={<Page502 />} />
        <Route exact path="/copytrade" element={<PrivateRoute> <Copytrade /> </PrivateRoute>} />
        <Route exact path="/copysetting" element={<CopySettings />} />
        <Route exact path="/chart/:id" element={<Chart />} />

        <Route exact path="/future-copy" element={<Copytradecopy />} />
      </Routes>


    </div>
  );
}

export default App;
