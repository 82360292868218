import { Button, Grid, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'

// import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import nodata from '../../Img/thankyou.gif'

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Btcoin from '../../Img/btcoin.png'
import { Drop, Drop2, Info, M1, M2, M3, Msg, Trading, Wifi } from '../../SvgCollection';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { useTheme, useMediaQuery } from '@mui/material';
import usercalls from "../../Axios";

import AppBar from '@mui/material/AppBar';
import Modal from '@mui/material/Modal';

import Toolbar from '@mui/material/Toolbar';

import Drawer from '@mui/material/Drawer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SpTab from './SpTab';
import Transfer from './Transfer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',  // Start from the left side of the screen
    transform: 'translate(-50%, -50%)', // Center vertically
    "@media (max-width: 767.98px)": {
        width: '80%',
        // left: '18%'
    },
    width: 320,
    bgcolor: 'var(--box-bg)',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '12px',
    pt: 2,
    px: 4,
    pb: 3,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function PlaceOrderFuture({ prices, setHistoryStatus }) {


    const navigate = useNavigate()
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobilemd = useMediaQuery(theme.breakpoints.up('md'));

    // const [marginCont, setMarginCont] = useState([
    //     { name: 'Standard' },
    //     { name: 'Auto-Borrow' },
    //     { name: 'Auto-Reply' },
    //     { name: 'Auto-Borrow & Reply' },
    // ])
    const [marginIndex, setMarginIndex] = useState()
    const handleMarginCont = (val) => {
        setMarginIndex(val)
    }

    const [value2, setValue2] = useState(0);

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const { id } = useParams()
    const location = useLocation()
    var paths = location?.pathname?.split("/")[1]

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const path = usercalls()

    const [value3, setValue3] = useState(0);
    const [total, setTotal] = useState(0)

    const handleChange3 = (event, newValue) => {

        setValue3(newValue);
        setOrderPrice("")
        setQuantity("")
    };


    const [value4, setValue4] = useState(0);
    const [loading, setLoading] = useState(false);
    const [side, setSide] = useState("LIMIT")

    const handleChange4 = (event, newValue) => {
        var sides = ["LIMIT", "MARKET"]
        setSide(sides[newValue])
        setValue4(newValue);
    };

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open, val) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setValue3(val)

        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };


    const [cross, setCross] = useState('CROSSED')

    const [crossVal, setCrossVal] = useState('Cross')

    const [xVal, setXVal] = useState(0)
    const [xVal2, setXVal2] = useState(0)

    const [xValSet, setXValSet] = useState(0)



    const [orderPrice, setOrderPrice] = useState()
    const [quantity, setQuantity] = useState()
    const [macLeverage, setMaxLeverage] = useState(0);
    const [balanceBuy, setBalanceBuy] = useState(0)
    const [balanceSell, setBalanceSell] = useState(0)

    const onCha = (val) => {
        val.preventDefault()
        console.log("🚀 ~ onCha ~ val:", val.target.value)
        setOrderPrice(val.target.value)
    }

    const getBalance = async () => {
        try {
            const url = `${consts.BackendUrl}/wallet/getWallet`
            var payload = {
                ccy: id
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                if (paths == "future") {
                    setBalanceBuy(result?.result?.quoteCoin?.futureBalance)
                    setBalanceSell(result?.result?.baseCoin?.futureBalance)
                } else if (paths == "trade") {
                    setBalanceBuy(result?.result?.quoteCoin?.balance)
                    setBalanceSell(result?.result?.baseCoin?.balance)
                }

            }
        } catch (error) {
            console.log("🚀 ~ getBalance ~ error:", error)
        }
    }

    useEffect(() => {
        if (id) {
            getBalance()
        }
    }, [id])

    useEffect(() => {
        if (prices?.length > 0) {
            setOrderPrice(Number(prices[0])?.toFixed(4))
            setQuantity(Number(prices[1])?.toFixed(4))
        }
    }, [prices])

    const placeOrderTrade = async (type) => {
        try {
            const per = (balanceBuy * 100) / 100
            if (side == 'LIMIT' && (!orderPrice || !quantity)) {
                toast.error("Please Enter Price and Quantity")
            }
            else if (!quantity) {
                toast.error("Please Enter Quantity")
            }
            // else if (type == "BUY" && balanceBuy == 0) {
            //     toast.error("Insufficient Balance")
            // } else if (type == "SELL" && balanceSell == 0) {
            //     toast.error("Insufficient Balance")
            // }
            else {
                console.log("payload working");
                // var payload = {
                //     ccy: id?.split("_")?.join(""),
                //     type: side,
                //     price: orderPrice,
                //     quantity: quantity,
                //     side: type,
                // }
                var payload = {
                    ccy: id,
                    side: type,
                    positionSide: type == "BUY" ? "LONG" : "SHORT",
                    type: side,
                    quantity: 5,//Number(xVal2).toFixed(3), //"0.01", // total, 
                    price: orderPrice
                }
                console.log(payload, "payload");
                // setQuantity((pers / orderPrice)?.toFixed(4))
                // const url = `${consts.BackendUrl}/binanceTrade/trade`
                const url = `${consts.BackendUrl}/binanceTrade/future/trade`
                const response = await path.postCall({ url, payload });
                const result = await response.json();
                if (result?.success) {
                    toast.success(result?.message)
                    setQuantity("")
                    setOrderPrice("")
                    getBalance()
                    setXVal2()
                    setHistoryStatus(Math.floor(Math.random() * 90) + 10)
                } else {
                    toast.error(result?.message)
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log("🚀 ~ placeOrderTrade ~ error:", error)
        }
    }


    const setPercent = async (per) => {
        // size = 1.) Allocation = Percentage * Balance / 100  2.) Allocation *  / Price
        try {

            // var pers = (per / 100) * Number(balanceBuy)
            // // setTotal((pers).toFixed(4))
            // setQuantity((pers / orderPrice)?.toFixed(4))
            // setXVal((pers / orderPrice)?.toFixed(4));
            // console.log(per,balanceBuy,orderPrice,"balanceBuybalanceBuy");
            var allocation = (per * Number(balanceBuy)) / 100;

            var sz = allocation / Number(orderPrice);
            // console.log(sz,"sz");
            setQuantity(sz.toFixed(4));
            setXVal2(sz.toFixed(4));


        } catch (error) {
            console.log("🚀 ~ setPercent ~ error:", error)
        }
    }


    const placeOrder = (
        <>

            <Box className=''>
                {!mobilemd && <div className="text-right">
                    <HighlightOffIcon sx={{ fill: 'var(--clr-font)', cursor: 'pointer' }} onClick={(() => { setState({ bottom: false }) })} />
                </div>}
                <div className='display-2'>
                    <Tabs value={value2} onChange={handleChange2} aria-label="basic tabs example" className='reUp mob'>
                        <Tab label="Place Order" {...a11yProps(0, "parent")} />

                    </Tabs>
                    <Transfer />
                </div>



            </Box>
            {/* <CustomTabPanel value={value2} index={0} className='spot-tab mt-10'> */}
            <Grid container className='mt-10'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SpTab />

                    <Grid container>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="display-1 mt-10" style={{ gap: "5px" }}>
                                <div className='cross'>
                                    <div onClick={handleOpen}>
                                        {crossVal}
                                    </div>
                                </div>
                                <div className='x50' onClick={handleOpen2}>
                                    <div>
                                        {xValSet}X
                                    </div>
                                </div>
                            </div>

                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ width: '100%' }} className="mt-10">
                                <Box>
                                    <Tabs value={value4} onChange={handleChange4} aria-label="basic tabs example" className='tabs-trade'>
                                        <Tab label="Limit" {...a11yProps(0)} />
                                        <Tab label="Market" {...a11yProps(1)} />
                                        {/* <Tab label="Advanced Limit" {...a11yProps(2)} /> */}
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value4} index={0}>
                                    {/* <div className='display-1' style={{ overflowX: 'scroll' }}>
                                                    {marginCont.map((row, i) => {
                                                        return (
                                                            <div className={marginIndex === i ? "ft-btn active" : "ft-btn"} onClick={(() => { handleMarginCont(i) })}>
                                                                <Button variant='contained'>
                                                                    {row.name}
                                                                </Button>
                                                            </div>
                                                        )
                                                    })}

                                                </div> */}
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="display-2 mt-10">
                                                <div className="aval">
                                                    Available
                                                </div>
                                                <div className="display-1 usd">
                                                    <div className="">
                                                        {Number(balanceBuy).toFixed(6)} {id?.split("_")[1]}
                                                    </div>
                                                </div>

                                            </div>
                                            <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                    }}
                                                    placeholder='Price'
                                                    value={orderPrice}
                                                    onChange={(e) => {
                                                        setOrderPrice(e.target.value)
                                                    }}
                                                    type="number"
                                                />
                                            </FormControl>
                                            <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field al-right" onChange={(e) => {
                                                console.log(e, "weightweight")
                                            }}>
                                                <OutlinedInput
                                                    id="outlined-adornment-weight x2"
                                                    endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                    startAdornment={<InputAdornment position="start" className='adroment'>Size</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                    }}
                                                    value={xVal2}
                                                    // onChange={(e) => {
                                                    //     console.log(e,"weightweight")
                                                    //     setQuantity(e?.target?.value)
                                                    // }}
                                                    onChange={(e) => { setXVal2(e?.target?.value); setQuantity(e?.target?.value) }}
                                                    placeholder='Size'
                                                    type="number"
                                                />
                                            </FormControl>
                                            {/* <div className="mt-20 sld">
                                                    <Slider defaultValue={xVal2} aria-label="Default" valueLabelDisplay="auto" onChange={((e) => { setPercent(e.target.value); setXVal2(e.target.value) })} />
                                                    <div className=" display-2">
                                                        <div className="usd">1%</div>
                                                        <div className="usd">100%</div>
                                                    </div>
                                                </div> */}

                                            <div class="mydict">
                                                <div>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(25) })}>25%</span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(50) })}>50%</span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(75) })}>75%</span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(100) })}>100%</span>
                                                    </label>

                                                </div>
                                            </div>
                                            <div className="display-2 mt-20">
                                                <div className="aval display-1">
                                                    Buy
                                                    <div className="usd">
                                                        {Number(balanceBuy).toFixed(6)} {id?.split("_")[0]}
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Sell
                                                    <div className="usd">
                                                        {Number(balanceBuy).toFixed(6)} {id?.split("_")[0]}
                                                    </div>
                                                </div>
                                            </div>




                                            <div className=" mt-30 display-2" style={{ gap: '10px' }}>
                                                <div className="buy-btn w2">
                                                    <Button variant='contained' onClick={() => { placeOrderTrade("BUY") }}  >Buy/Long</Button>
                                                </div>
                                                <div className="sell-btn w2">
                                                    <Button variant='contained' onClick={() => { placeOrderTrade("SELL") }}  >Sell/Short</Button>
                                                </div>
                                            </div>

                                            {/* <div className="display-2 mt-20">
                                                <div className="aval display-1">
                                                    Est.Liq.Price
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Est.Liq.Price
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="display-2 mt-10">
                                                <div className="aval display-1">
                                                    Cost
                                                    <div className="usd">
                                                        -- USDT
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Cost
                                                    <div className="usd">
                                                        -- USDT
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-2 mt-10">
                                                <div className="aval display-1">
                                                    Max
                                                    <div className="usd">
                                                        -- USDT
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Max
                                                    <div className="usd">
                                                        -- USDT
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={value4} index={1}>
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="display-2 mt-20">
                                                <div className="aval">
                                                    Available
                                                </div>
                                                <div className="display-1 usd">
                                                    <div className="">
                                                        {Number(balanceBuy).toFixed(6)} {id?.split("_")[1]}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'weight',
                                                        }}
                                                        placeholder='Price'
                                                        value={orderPrice}
                                                        onChange={(e) => {
                                                            setOrderPrice(e.target.value)
                                                        }}
                                                        type="number"
                                                    />
                                                </FormControl> */}
                                            <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field ">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight x2"
                                                    endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                    // startAdornment={<InputAdornment position="start" className='adroment'>Size</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                    }}
                                                    value={quantity}
                                                    onChange={(e) => {
                                                        setQuantity(e.target.value)
                                                    }}
                                                    placeholder='Size'

                                                    type="number"
                                                />
                                            </FormControl>
                                            <div class="mydict">
                                                <div>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(25) })}>25%</span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(50) })}>50%</span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(75) })}>75%</span>
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="radio" />
                                                        <span onClick={((e) => { setPercent(100) })}>100%</span>
                                                    </label>

                                                </div>
                                            </div>
                                            {/* <div className="mt-20 sld">
                                                    <Slider defaultValue={xVal2} aria-label="Default" valueLabelDisplay="auto" onChange={((e) => { setPercent(e.target.value); setXVal2(e.target.value) })} />
                                                    <div className=" display-2">
                                                        <div className="usd">1%</div>
                                                        <div className="usd">100%</div>
                                                    </div>
                                                </div> */}
                                            <div className="display-2 mt-20">
                                                <div className="aval display-1">
                                                    Buy
                                                    <div className="usd">
                                                        {Number(balanceBuy).toFixed(6)} {id?.split("_")[1]}
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Sell
                                                    <div className="usd">
                                                        {Number(balanceBuy).toFixed(6)} {id?.split("_")[1]}
                                                    </div>
                                                </div>
                                            </div>




                                            <div className=" mt-30 display-2" style={{ gap: '10px' }}>
                                                <div className="buy-btn w2">
                                                    <Button variant='contained' onClick={() => { placeOrderTrade("BUY") }}  >Buy/Long</Button>
                                                </div>
                                                <div className="sell-btn w2">
                                                    <Button variant='contained' onClick={() => { placeOrderTrade("SELL") }}  >Sell/Short</Button>
                                                </div>




                                            </div>

                                            <div className="display-2 mt-20">
                                                <div className="aval display-1">
                                                    Est.Liq.Price
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Est.Liq.Price
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-2 mt-10">
                                                <div className="aval display-1">
                                                    Cost
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Cost
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="display-2 mt-10">
                                                <div className="aval display-1">
                                                    Max
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                                <div className="aval display-1">
                                                    Max
                                                    <div className="usd">
                                                        --USDT
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </CustomTabPanel>

                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                        </Grid>
                    </Grid>




                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                </Grid>
            </Grid>
            {/* </CustomTabPanel> */}

        </>
    )
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, padding: !mobilemd ? '15px' : '' }}
            role="presentation"
        >
            {placeOrder}
        </Box>
    );

    const handleMarginType = async () => {

        try {
            setLoading(true);
            const url = `${consts?.BackendUrl}/binanceTrade/future/changeMarginType`
            const payload = {
                symbol: id,
                marginType: cross
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                toast.success(`Changed To ${cross}`);
            } else {
                toast.error(result?.message);
            }


        } catch (error) {
            console.log("🚀 ~ changeMarginType ~ error:", error)
        } finally {
            setLoading(false);
            handleClose();
            setCross("CROSSED");
        }

    };

    const handleLeverage = async () => {

        try {
            setLoading(true);
            const url = `${consts?.BackendUrl}/binanceTrade/future/changeMarginLeverage`
            const payload = {
                symbol: id,
                leverage: xVal
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                toast.success(result?.message);
            } else {
                toast.error(result?.message);
            }


        } catch (error) {
            console.log("🚀 ~ handleLeverage ~ error:", error)
        } finally {
            setLoading(false);
            handleClose2();
        }

    };

    const getMarginType = async () => {

        try {

            const url = `${consts?.BackendUrl}/binanceTrade/future/getMarginTypeLeverage`
            const payload = {
                symbol: id
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                setCross(result?.result?.marginType)
                setXVal(result?.result?.leverage)
                setXValSet(result?.result?.leverage)
                setCrossVal(result?.result?.marginType == "CROSSED" ? "Cross" : "Isolated")
                setMaxLeverage(result?.result?.maxLeverage);
                // console.log(result?.result,"result?.leverage");
            }


        } catch (error) {
            console.log("🚀 ~ handleLeverage ~ error:", error)
        }

    };

    useEffect(() => {
        getMarginType();
    }, [id]);

    return (
        <div className='trade-left'>
            <ToastContainer />
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                    <Box sx={!mobilemd && { display: 'none' }}>
                        {placeOrder}
                    </Box>

                    <Modal
                        open={open}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="text-right">
                                <HighlightOffIcon onClick={handleClose} className='cursor' sx={{ fill: 'var(--clr-font)' }} />
                            </div>
                            <Typography id="modal-modal-title" variant="h6" component="h2" className='font-clr'>
                                Margin Mode
                            </Typography>
                            <div className="mt-20 font-clr">
                                {/* BTCUSDT */}
                                {id?.split("_")?.join("/")}
                            </div>
                            <div className="mt-20" >
                                <div id="firstFilter" class="filter-switch">
                                    <input id="option1" name="options" type="radio" value="CROSSED" onChange={((e) => { setCross(e.target.value) })} defaultChecked={cross === 'Cross' && true} />
                                    <label class="option" for="option1">Cross</label>
                                    <input id="option2" name="options" type="radio" value="ISOLATED" onChange={((e) => { setCross(e.target.value) })} defaultChecked={cross === 'Isolated' && true} />
                                    <label class="option" for="option2">Isolated</label>
                                    <span class="background"></span>
                                </div>

                            </div>
                            <div className="cnfi mt-50">
                                <Button disabled={loading} onClick={(() => { handleMarginType(); setCrossVal(cross == "CROSSED" ? "Cross" : cross); })}>
                                    Confirm
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                    <Modal
                        open={open2}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="text-right">
                                <HighlightOffIcon onClick={handleClose2} className='cursor' sx={{ fill: 'var(--clr-font)' }} />
                            </div>
                            <Typography id="modal-modal-title" variant="h6" component="h2" className='font-clr'>
                                Leverage Mode
                            </Typography>
                            <div className="mt-20 font-clr">
                                {/* BTCUSDT */}
                                {id?.split("_")?.join("/")}
                            </div>
                            <div className="mt-20 font-clr display-2">
                                <div className="cursor">
                                    <RemoveCircleOutlineIcon sx={{ fill: 'red', fontSize: '30px !important' }} onClick={(() => { if (xVal > 1) { setXVal(xVal - 1) } })} />
                                </div>

                                <div className="">
                                    <div className="">{xVal}X</div>
                                </div>

                                <div className="cursor">
                                    <AddCircleOutlineIcon sx={{ fill: 'green', fontSize: '30px !important' }} onClick={(() => { if (xVal < macLeverage) { setXVal(xVal + 1) } })} />
                                </div>
                            </div>
                            <div className="mt-20 sld">
                                <Slider defaultValue={xVal} min={1} max={macLeverage} aria-label="Default" valueLabelDisplay="auto" onChange={((e) => { setXVal(e.target.value) })} />
                                <div className="mt-10 display-2">
                                    <div className="">1X</div>
                                    {/* <div className="">100X</div> */}
                                    <div className="">{`${macLeverage}X`}</div>
                                </div>
                            </div>

                            <div className="cnfi mt-50">
                                <Button disabled={loading} onClick={(() => { handleLeverage(); setXValSet(xVal); })}>
                                    Confirm
                                </Button>
                            </div>
                        </Box>

                    </Modal>
                </Grid>
            </Grid >

            {!mobilemd && <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, }}>
                <Toolbar sx={{ justifyContent: 'space-evenly', }}>

                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className="buy-h-btn display-2" style={{ width: '100%' }}>
                                <Button variant='contained' onClick={toggleDrawer(anchor, true, 0)}>{'Buy'}</Button>
                            </div>

                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                // onClose={toggleDrawer(anchor, false)}
                                className='drawer-bottom'
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}


                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className="sell-h-btn display-2" style={{ width: '100%' }}>

                                <Button variant='contained' onClick={toggleDrawer(anchor, true, 1)}>{'Sell'}</Button>
                            </div>

                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                // onClose={toggleDrawer(anchor, false)}
                                className='drawer-bottom'
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}

                </Toolbar>
            </AppBar>}

        </div >
    )
}

export default PlaceOrderFuture
