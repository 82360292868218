import { Grid, Button } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Header from "../Header/Header";
import "./Kyc.scss";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import baseURL from "./flagcode.json";
import { makeStyles } from "@mui/styles";
import frontpage from "../../Img/frontpage.png";
import backpage from "../../Img/backpage.png";
import selfiehand from "../../Img/selfie-hand.png";
import { styled, alpha } from "@mui/material/styles";
import usercalls from "../../Axios";
import consts from '../../constant';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment'

const useStyles = makeStyles({
  logintextbox: {
    background: "transparent !important",
    boxShadow: "none !important",
    marginTop: "15px !important",
    marginBottom: "15px !important",
    "& input": {
      color: "var(--clr-font)",
    },
  },
  input: {
    background: "transparent !important",
    boxShadow: "none !important",
    marginTop: "15px !important",
    marginBottom: "15px !important",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  loginwhole: {
    background: "transparent !important",
    boxShadow: "none !important",
    textAlign: "left !important",
    color: "#ccc",
    padding: "20px 55px !important",
    background: "transparent !important",
    "@media (max-width: 991.98px)": {
      padding: "20px 10px !important",
    },
    "& input": {
      border: "1px solid #ccc",
      borderRadius: "5px",
      marginBottom: "0px",
      width: "100%",
      color: "#ccc !important",
    },
    "& label": {
      color: "#ccc",
      background: "#131a26 !important",
    },
    "& button": {
      fontSize: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
      marginTop: "20px",
    },
  },
  qrlogin: {
    background: "transparent !important",
    boxShadow: "none !important",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h3": {
      color: "#ccc",
    },
    "& p": {
      color: "#ccc",
    },
  },

  marketpart: {
    background: "transparent !important",
    boxShadow: "none !important",

    "& h3": {
      color: "#ababab",
      fontSize: "14px",
      textAlign: "left",
      marginBottom: "30px",
      marginTop: "0px",
    },
  },
  tabpartmarketitem: {
    background: "transparent !important",
    boxShadow: "none !important",
    marginRight: "0px !important",
    marginLeft: "0px !important",
    paddingBottom: "19%",

    "& button": {
      fontSize: "16px",
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      marginRight: "10px",
    },
  },

  tabpartmarketgrid: {
    marginRight: "0px !important",
    marginLeft: "0px !important",
    marginTop: "50px !important",
    paddingLeft: "0px !important",
  },

  depositcoinpart: {
    background: "transparent !important",
    boxShadow: "none !important",
  },
  depositcoinpartpaper: {
    background: "transparent !important",
    boxShadow: "none !important",
    marginTop: "10px",
  },

  depositcoinpartpaperbottom: {
    background: "transparent !important",
    boxShadow: "none !important",
    marginTop: "40px",
  },

  modalcoinselectbox: {
    bgcolor: "var(--clr-box)",
    border: "none !important",
    borderRadius: "10px",
    overflow: "hidden",
    "& h2": {
      color: "#fff !important",
      fontSize: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  searchboxwallet: {
    background: "transparent !important",
    border: "1px solid rgb(86 84 84)",
    marginBottom: "30px",
    marginTop: "30px",
    color: "var(--clr-font) !important;",
    "& input": {
      color: "var(--clr-font)",
    },
  },

  coinsblock: {
    borderRadius: "0px !important",
    boxShadow: "none !important",
    padding: "20px 55px !important",
    background: "transparent !important",
    "@media (max-width: 991.98px)": {
      padding: "20px !important",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--clr-box)",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  "@media(max-width:575.98px)": {
    width: "80%",
  },
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Kyc = () => {
  const classes = useStyles();

  const path = usercalls()

  const [countryopen, setCountryOpen] = useState(false);
  const handleCountryOpen = () => setCountryOpen(true);
  const handleCountryClose = () => setCountryOpen(false);
  const [search, setSearch] = useState();
  const [countryerr, setcountryerr] = useState(null);
  const [coin, setCoin] = useState({ image: "", name: "", code: "" });
  const navigate = useNavigate()
  const location = useLocation()

  //detail upload
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNo, setPhoneNo] = useState()
  const [gender, setGender] = useState('')
  const [dob, setDob] = useState()
  const [country, setCountry] = useState(null)
  const [country_code, setCountry_code] = useState(null)
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [zipcode, setZipcode] = useState()
  const [bankName, setBankName] = useState('')
  const [accountNo, setAccountNo] = useState()
  const [ifscCode, setIfscCode] = useState()
  const [docNum, setDocNum] = useState()
  const [expiryDate, setExpiryDate] = useState()
  const [proofAddr, setProofAddr] = useState('')
  const [load, setLoad] = useState(false)

  const firstNameRef = useRef(null);
  const stateRef = useRef(null)
  const banknameRef = useRef(null)
  const docNumRef = useRef(null)
  const proofAddrRef = useRef(null)
  const token = localStorage.getItem('Rikosta')

  //image upload

  // const [Image, setImage] = useState(null);
  // const [ImageBack, setImageBack] = useState(null);
  // const [ImageSelf, setImageSelf] = useState(null);
  // const [ImageProof, setImageProof] = useState(null);


  const [imageUrl, setImageUrl] = useState(null);
  const [imageUrlBack, setImageUrlBack] = useState(null);
  const [imageUrlSelf, setImageUrlSelf] = useState(null);
  const [imageUrlProof, setImageUrlProof] = useState(null);
  const reg = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   console.log(reader, "result of file");

  //   reader.onloadend = () => {
  //     setImageUrl(reader.result);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleError = () => {
    setFirstName('')
    setLastName('')
    setPhoneNo()
    setGender('')
    setDob()
    setCountry(null)
    setCountry_code(null)
    setState('')
    setCity('')
    setAddress('')
    setZipcode('')
    setBankName('')
    setAccountNo()
    setIfscCode()
    setDocNum()
    setExpiryDate()
    setProofAddr('')
    setImageUrl(null)
    setImageUrlBack(null)
    setImageUrlSelf(null)
    setImageUrlProof(null)
  }

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    const formdata = new FormData();
    formdata.append("image", file)

    const url = `${consts.BackendUrl}/users/imageUpload`

    const { data } = await axios.post(url, formdata, {
      headers: {
        'Authorization': `${token}`,
      },
    });
    if (data.success) {
      setImageUrl(data.result)
    }
    else {
      setImageUrl(null)
    }
  };

  const handleDocUpload = async (event) => {
    const file = event.target.files[0];

    const formdata = new FormData();
    formdata.append("image", file)

    const url = `${consts.BackendUrl}/users/imageUpload`

    const { data } = await axios.post(url, formdata, {
      headers: {
        'Authorization': `${token}`,
      },
    });
    if (data.success) {
      setImageUrlBack(data.result)
    }
    else {
      setImageUrl(null)
    }
  };
  const handleSelfUpload = async (event) => {

    const file = event.target.files[0];

    const formdata = new FormData();
    formdata.append("image", file)

    const url = `${consts.BackendUrl}/users/imageUpload`

    const { data } = await axios.post(url, formdata, {
      headers: {
        'Authorization': `${token}`,
      },
    });
    if (data.success) {
      setImageUrlSelf(data.result)
    }
    else {
      setImageUrl(null)
    }

  };


  const handleProofUpload = async (event) => {
    const file = event.target.files[0];

    const formdata = new FormData();
    formdata.append("image", file)

    const url = `${consts.BackendUrl}/users/imageUpload`

    const { data } = await axios.post(url, formdata, {
      headers: {
        'Authorization': `${token}`,
      },
    });
    if (data.success) {
      setImageUrlProof(data.result)
    }
    else {
      setImageUrl(null)
    }
  };

  const handleDateChange = (event) => {
    const newValue = event.$d;
    const today = new Date();
    const age = today.getFullYear() - newValue.getFullYear();
    if (newValue === '') {
      toast.error('Please Select Date');
    } else if (age < 18) {
      toast.error('You must be at least 18 years old');
    } else {

    }
    setDob(moment(newValue).format('YYYY-MM-DD'));
  };

  const handleExpiryDate = (event) => {
    const newValue = event.$d;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (newValue < today) {
      toast.error('Please Select Future Date')
    }
    setExpiryDate(moment(newValue).format('YYYY-MM-DD'))
  }



  const createKyc = async () => {
    try {
      if (phoneNo && phoneNo.length < 6) {
        toast.error('Phone Number Must be Greater than 6')
        firstNameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      else if (phoneNo && phoneNo.length > 15) {
        toast.error("Phone Number Must be Less than 15")
        firstNameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      // else if (ifscCode && !(reg.test(ifscCode))) {
      //   toast.error("Invalid IFSC code")
      //   stateRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      // }
      else {
        setLoad(true)
        const url = `${consts.BackendUrl}/users/createKyc`
        const payload = {
          first_name: firstName,
          last_name: lastName,
          phone_no: phoneNo,
          gender: gender,
          dob: dob,
          country: country,
          country_code: country_code,
          state: state,
          city: city,
          address: address,
          zipcode: zipcode,
          bank_name: bankName,
          account_no: accountNo,
          ifsc_code: ifscCode,
          document_num: docNum,
          frontImg: imageUrl,
          backImg: imageUrlBack,
          selfieImg: imageUrlSelf,
          proof_address: proofAddr,
          residential_img: imageUrlProof,
          expiry_date: expiryDate
        }
        // console.log(payload, 'payloada');
        const response = await path.postCall({ url, payload });
        const result = await response.json();
        if (result.success) {
          setLoad(false)
          toast.success(result.message)
          setTimeout(() => {
            handleError()
            navigate(`/trade/${consts?.MainPair}`)
          }, 1500)
        }
        else {
          setLoad(false)
          toast.error(result.message)
          if (result?.message === 'You have already submitted KYC') {
            handleError()
            navigate(`/trade/${consts?.MainPair}`)
          }
          else if (result?.message === 'Please Enter First Name' ||
            result?.message === 'Please Enter Last Name' ||
            result.message === 'Please Enter Phone Number' ||
            result?.message === 'Please Select Gender' ||
            result?.message === 'Please Enter DOB' ||
            result?.message === 'Please Enter Country'
          ) {
            firstNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

          }
          else if (result?.message === 'Please Enter State' ||
            result?.message === 'Please Enter City' ||
            result.message === 'Please Enter Address' ||
            result?.message === 'Please Enter Zip Code'
          ) {
            stateRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          else if (result?.message === 'Please Enter Bank Name' ||
            result?.message === 'Please Enter Account Number' ||
            result.message === 'Please Enter IFSC Code'
          ) {
            banknameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

          }
          else if (result?.message === 'Please Enter Document Number' ||
            result?.message === 'Please Upload Front Image' ||
            result.message === 'Please Upload Back Image' ||
            result.message === 'Please Upload Selfie Image' ||
            result.message === 'Please Select Expiry date'
          ) {
            docNumRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

          }
          else {
            proofAddrRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
    } catch (error) {
      setLoad(false)
      console.log("🚀 ~ kyc ~ error:", error);
    }
  }

  const handleSelectCoin = (country) => {
    setcountryerr(null);
    setCountry(country.name);
    setCountry_code(country.code)
    setCoin({
      image: country.code,
      name: country.name,
      code: country.dial_code,
    });
    handleCountryClose();
  };


  const getKyc = async () => {
    try {
      const url = `${consts.BackendUrl}/users/mykycdetail`
      const response = await path.getCallWithToken({ url });
      const result = await response.json();
      if (result?.success == true) {
        if (result?.result?.status == "0") {
          toast.success("You KYC is Under Verification")
          setTimeout(() => {
            navigate(`/profile`)
          }, 2000)
        } else if (result?.result?.status == "1") {
          toast.success("You KYC is Verified")
          setTimeout(() => {
            navigate(`/profile`)
          }, 2000)
        } else if (result?.result?.status == "2") {
          toast.error("You KYC is Rejected")
        }
      } else {
      }
    } catch (error) {
      console.log("🚀 ~ getKyc ~ error:", error)
    }
  }


  useEffect(() => {
    // getKyc()
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="kyc-full">
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Header />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="support-head contain-width">
              <h1>KYC Verification</h1>
            </div>
            <Grid container className="contain-width" spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="kyc-step-number-heading display-2">
                  <div className="kyc-step-number">01</div>
                  <div className="kyc-step-heading">
                    <h3 className="sub-heading-part">
                      Step 1: Personal Information
                    </h3>
                    <p>
                      Simple personal information, required for identification
                    </p>
                  </div>
                </div>
                <div className="detail-cnt">
                  <p
                    style={{
                      color: "#ee7409e6",
                      margin: "0px 0px 20px 0px",
                      fontWeight: "600",
                    }}
                  >
                    Please carefully fill out the form with your personal details.
                    Your can’t edit these details once you submitted the form.
                  </p>
                  <Grid
                    container
                    spacing={2}
                    className=""
                    id="kyc-verification-id"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>First Name</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        // type="text"
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => {
                          let value = e.target.value
                          value = value.replace(/[^A-Za-z\s]/g, '')
                          setFirstName(value)
                        }}
                        inputRef={firstNameRef}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Last Name</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => {
                          let value = e.target.value
                          value = value.replace(/[^A-Za-z\s]/g, '')
                          setLastName(value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Phone Number</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        type="number"
                        variant="outlined"
                        value={phoneNo}
                        onChange={(e) => { setPhoneNo(e.target.value) }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Gender</label>
                      <FormControl fullWidth>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={gender}
                          onChange={(e) => { setGender(e.target.value) }}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="datepicpart"
                    >
                      <label>DOB</label>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="date-pick"
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker fullwidth
                            value={dob}
                            onChange={e => {
                              handleDateChange(e);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Country</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        variant="outlined"
                        className={classes.logintextbox}
                        onClick={handleCountryOpen}
                        value={`${coin.name}`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Modal
                        open={countryopen}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={classes.modalcoinselect}
                        he
                      >
                        <Box sx={style} className={classes.modalcoinselectbox}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Select Country{" "}
                            <CancelIcon onClick={handleCountryClose} />
                          </Typography>

                          <Paper
                            className={classes.searchboxwallet}
                            // component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              placeholder="Search Country name"
                              inputProps={{ "aria-label": "search google maps" }}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                            <IconButton
                              type="button"
                              sx={{ p: "10px" }}
                              aria-label="search"
                            >
                              <SearchIcon />
                            </IconButton>
                          </Paper>

                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <ul className="coin-list-menu country-detailed-list cursor">
                              {baseURL?.map((country, index) => {
                                if (search) {
                                  if (
                                    country.name.includes(
                                      search?.charAt(0).toUpperCase() +
                                      search?.slice(1)
                                    )
                                  ) {
                                    return (
                                      <>
                                        <li
                                          key={index}
                                          onClick={() =>
                                            handleSelectCoin(country)
                                          }
                                        >
                                          <div className="coin-img country-img">
                                            <div className="coin-img-inner country-dtls">
                                              <div className="flag-image-code">
                                                <img
                                                  src={`https://flagicons.lipis.dev/flags/4x3/${country.code.toLocaleLowerCase()}.svg`}
                                                />
                                              </div>
                                              <h3>{country.name}</h3>
                                            </div>
                                            <h4>{country.dial_code}</h4>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  }
                                } else {
                                  return (
                                    <>
                                      {" "}
                                      <li
                                        key={index}
                                        onClick={() => handleSelectCoin(country)}
                                      >
                                        <div className="coin-img country-img">
                                          <div className="coin-img-inner country-dtls">
                                            <div className="flag-image-code">
                                              <img
                                                src={`https://flagicons.lipis.dev/flags/4x3/${country.code.toLocaleLowerCase()}.svg`}
                                              />
                                            </div>
                                            <h3>{country.name}</h3>
                                          </div>
                                          <h4>{country.dial_code}</h4>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              })}
                            </ul>
                          </Typography>
                        </Box>
                      </Modal>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>State</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        type="text"
                        variant="outlined"
                        value={state}
                        onChange={(e) => {
                          let value = e.target.value
                          value = value.replace(/[^A-Za-z\s]/g, '')
                          setState(value)
                        }}
                        inputRef={stateRef}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>City</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        variant="outlined"
                        value={city}
                        onChange={(e) => {
                          let value = e.target.value
                          value = value.replace(/[^A-Za-z\s]/g, '')
                          setCity(value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="address-box">
                        <label>Address</label>
                        <TextField
                     id="outlined-multiline-static"
                    //  label="Multiline"
                     multiline
                     rows={3}
                          name="message"
                          required="required"
                          value={address}
                          onChange={(e) => { setAddress(e.target.value) }}
                          sx={{width:'100%'}}
                          />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Zip / Postal Code</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        variant="outlined"
                        type="number"
                        value={zipcode}
                        onChange={(e) => { setZipcode(e.target.value) }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="kyc-step-number-heading display-2">
                  <div className="kyc-step-number">02</div>
                  <div className="kyc-step-heading">
                    <h3 className="sub-heading-part">Step 2: Bank Details</h3>
                    <p>Simple Bank details, required for identification</p>
                  </div>
                </div>
                <div className="detail-cnt">
                  <p
                    style={{
                      color: "#ee7409e6",
                      margin: "0px 0px 20px 0px",
                      fontWeight: "600",
                    }}
                  >
                    Please carefully fill out the form with your bank details.
                    Your can’t edit these details once you submitted the form.
                  </p>
                  <Grid
                    container
                    spacing={2}
                    className=""
                    id="kyc-verification-id"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Bank Name</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        type="text"
                        variant="outlined"
                        value={bankName}
                        onChange={(e) => {
                          let value = e.target.value
                          value = value.replace(/[^A-Za-z\s]/g, '')
                          setBankName(value)
                        }}
                        inputRef={banknameRef}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>Account Number</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        variant="outlined"
                        type="number"
                        value={accountNo}
                        onChange={(e) => { setAccountNo(e.target.value) }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>IFSC Code</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        // type="number"
                        variant="outlined"
                        value={ifscCode}
                        onChange={(e) => { setIfscCode(e.target.value) }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="kyc-step-number-heading display-2">
                  <div className="kyc-step-number">03</div>
                  <div className="kyc-step-heading">
                    <h3 className="sub-heading-part">Step 3: ID Proof Details</h3>
                    <p>Upload documents to verify your identity.</p>
                  </div>
                </div>
                <div className="detail-cnt">
                  <p
                    style={{
                      color: "#ee7409e6",
                      margin: "0px 0px 20px 0px",
                      fontWeight: "600",
                    }}
                  >
                    Please upload any of the following personal document.
                  </p>
                  <Grid
                    container
                    spacing={2}
                    className=""
                    id="kyc-verification-id"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <label>ID document number</label>
                      <TextField
                        fullWidth
                        required
                        id="input-with-icon-textfield"
                        type="number"
                        variant="outlined"
                        value={docNum}
                        onChange={(e) => { setDocNum(e.target.value) }}
                        inputRef={docNumRef}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="datepicpart"
                    >
                      <label>Expiry date</label>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        className="date-pick"
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker fullwidth
                            value={expiryDate}
                            onChange={(e) => { handleExpiryDate(e) }} />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <div className="dealys-details">
                        <p
                          style={{
                            color: "#ee7409e6",
                            margin: "0px 0px 20px 0px",
                            fontWeight: "600",
                          }}
                        >
                          To avoid delays when verifying account, Please make sure
                          below:
                        </p>
                        <ul>
                          <li>Choosen credential must not be expired.</li>
                          <li>
                            Document should be good condition and clearly visible.
                          </li>
                          <li>
                            Make sure that there is no light glare on the card.
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      className="DOCUMENT-TYPE-UPLOAD"
                    >
                      <div className="inner-doc ticket-box">
                        <div className="text-center">ID Front Document</div>
                        <Button component="label" className="upload-detail">
                          <div className="uplad-btn">Upload</div>
                          <img
                            alt="Remy Sharp"
                            src={imageUrl ? imageUrl : frontpage}
                            className="uploaded-img"
                          />
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleImageUpload}
                          />
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      className="DOCUMENT-TYPE-UPLOAD"
                    >
                      <div className="inner-doc ticket-box">
                        <div className="text-center">ID Back Document</div>
                        <Button component="label" className="upload-detail">
                          <div className="uplad-btn">Upload</div>
                          <img
                            alt="Remy Sharp"
                            src={imageUrlBack ? imageUrlBack : backpage}
                            className="uploaded-img"
                          />
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleDocUpload}
                          />
                        </Button>
                      </div>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      className="DOCUMENT-TYPE-UPLOAD"
                    >
                      <div className="inner-doc ticket-box">
                        <div className="text-center">
                          Selfie with an ID document
                        </div>
                        <Button component="label" className="upload-detail">
                          <div className="uplad-btn">Upload</div>
                          <img
                            alt="Remy Sharp"
                            src={imageUrlSelf ? imageUrlSelf : selfiehand}
                            className="uploaded-img"
                          />
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(e) => { handleSelfUpload(e) }}
                          />
                        </Button>
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                <div className="kyc-step-number-heading display-2">
                  <div className="kyc-step-number">04</div>
                  <div className="kyc-step-heading">
                    <h3 className="sub-heading-part">Step 4: PROOF ADDRESS</h3>
                  </div>
                </div>
                <div className="detail-cnt">
                  <Grid container spacing={2} className="">
                    <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                      <div className="dealys-details">
                        <div className="address-proof">
                          <label>Proof of address</label>
                          <FormControl fullWidth>
                            <Select
                              required
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={proofAddr}
                              onChange={(e) => { setProofAddr(e.target.value) }}
                              inputRef={proofAddrRef}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ArrowDropDownIcon />
                                  </InputAdornment>
                                ),
                              }}
                            >
                              <MenuItem value="Bank">Bank Statement</MenuItem>
                              <MenuItem value="Tax">Tax Statement</MenuItem>
                              <MenuItem value="pension">
                                Pension Statement
                              </MenuItem>
                              <MenuItem value="regi">
                                Certificate of registration
                              </MenuItem>
                              <MenuItem value="bank">Bank confirmation</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <p
                          style={{
                            color: "#ee7409e6",
                            margin: "0px 0px 20px 0px",
                            fontWeight: "600",
                          }}
                        >
                          The following documents (not older than 2 months) are
                          accepted:
                        </p>
                        <ul>
                          <li>Utility bills (gas, electricity, water).</li>
                          <li>Telephone / Internet bill (no cell phone bill).</li>
                          <li>Pension statement</li>
                          <li>Tax statement</li>
                          <li>Certificate of registration</li>
                          <li>Bank confirmation</li>
                          <li>Advertising letters are not accepted!</li>
                        </ul>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      className="DOCUMENT-TYPE-UPLOAD"
                    >
                      <div className="inner-doc ticket-box">
                        <div className="text-center">
                          Residential document or phone document upload
                        </div>
                        <Button component="label" className="upload-detail">
                          <div className="uplad-btn">Upload</div>
                          <img
                            alt="Remy Sharp"
                            src={imageUrlProof ? imageUrlProof : frontpage}
                            className="uploaded-img"
                          />
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleProofUpload}
                          />
                        </Button>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="dealys-details">
                        <p
                          style={{
                            color: "#ee7409e6",
                            margin: "0px 0px 20px 0px",
                            fontWeight: "600",
                          }}
                        >
                          Note: To avoid delays with verification process, please
                          double-check to ensure the above requirements are fully
                          met. Chosen credential must not be expired.
                        </p>
                        <ul>
                          <li>
                            I have read the Terms and Condition and AML-KYC.
                          </li>
                          <li>
                            All the personal information I have entered is
                            correct.
                          </li>
                          <li>
                            I certify that, I am registering to participate in the
                            Switch distribution event in the capacity of an
                            individual (and beneficial owner) and not as an agent
                            or representative of a third party corporate entity.
                          </li>
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="submit-btn">
                    {
                      load ?
                        <Button variant="contained">Processing...</Button>
                        :
                        <Button variant="contained" onClick={() => { createKyc() }}>Submit</Button>

                    }
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Kyc;
