import { Button, Grid, TextField, Avatar } from "@mui/material";
import React, { useState } from "react";
import Header from "../Header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import { useForm, } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import axios from "axios";
import consts from "../../constant";
import usercalls from "../../Axios";
import { toast, ToastContainer } from "react-toastify";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddPayment() {
  const navigate = useNavigate();

  const val = useLocation();

  const result = val.state?.type;
  const stateData = val.state?.data;
  console.log(stateData, "resssssusuussuus");

  /*Data collection */

  const [name, setName] = useState("");
  const [upiId, setUpiId] = useState("");

  // const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file)

    const url = `${consts.BackendUrl}/users/imageUpload`

    const { data } = await axios.post(url, formdata, {
      headers: {
        'Authorization': `${localStorage.getItem('Rikosta')}`,
      },
    });
    if (data?.success) {
      setValue('Qr_code', data.result);
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setValue('qrImage', reader.result); // Set the image URL in form state
      };
      reader.readAsDataURL(file);
    }
  };
  const handleConfirm = () => {
    const formData = {
      name,
      upiId,
      imageUrl,
    };
    navigate("/paymentMethod", { state: formData });
  };


  const { register, handleSubmit, setValue, watch, formState: { isSubmitting, errors } } = useForm({
    mode: "onChange", defaultValues: {
      Name: stateData?.Name ? stateData.Name : "",
      Account_number: stateData?.Account_number ? stateData.Account_number : "",
      Ifsc_code: stateData?.Ifsc_code ? stateData.Ifsc_code : "",
      Acc_type: stateData?.Acc_type ? stateData.Acc_type : "",
      Bank_Name: stateData?.Bank_Name ? stateData.Bank_Name : "",
      Upi_id: stateData?.Upi_id ? stateData.Upi_id : "",
      _id: stateData?._id ? stateData._id : ""
    }
  });
  const imageUrl = watch('Qr_code');

  const path = usercalls()
  const onSubmit = async (data) => {
    if (stateData == undefined) {

      data.Type = result == "0" ? "BANK" : result == "1" ? "GPAY" : "UPI"
      console.log(data); // Handle form submission here
      const url = `${consts.BackendUrl}/p2p/createPaymentMethod`
      const response = await path.postCall({ url, payload: data });
      const results = await response.json();
      if (results?.success) {
        toast.success(results?.message)
        setTimeout(() => {
          navigate('/paymentMethod')
        }, 1000);
      }

    } else {
      const url = `${consts.BackendUrl}/p2p/editPaymentMethod`
      const response = await path.postCall({ url, payload: data });
      const results = await response.json();
      if (results?.success) {
        toast.success(results?.message)
        setTimeout(() => {
          navigate('/paymentMethod')
        }, 1000);
      }
    }
  };

  console.log(isSubmitting, "isSubmitting")

  return (
    <div className="Addpayment">
      <ToastContainer />
      <Grid container className="justi-center">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="ivon-back">
            <Link to="/paymentMethod" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/paymentMethod" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div>
          <div className='post-ad-title text-center'>

            {/* <ArrowBackIcon onClick={(() => { navigate(-1) })} /> */}
            Post Normal Ad
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={9}>
          {/* 
          <div class="ivon-back post-ad-title text-center">
            <Link to="/wallet" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/wallet" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
            Post Normal Ad
          </div> */}
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={5}>
          <div className="add-payment-main">
            <div className="paymeny-type-text">
              {result === "0"
                ? "BANK TRANSFER"
                : result === "1"
                  ? "GPAY"
                  : "UPI"}
            </div>
            {result === "0" ? (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="add-pay-input-main">
                    <div className="text">Account Holder Name</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Name"
                        variant="outlined"
                        {...register('Name', { required: 'Name is required' })}
                      />
                      <ErrorMessage errors={errors} name="Name" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="add-pay-input-main">
                    <div className="text">Account Number</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Account Number"
                        type="number"
                        variant="outlined"
                        {...register('Account_number', { required: 'Account Number is required' })}
                      />
                      <ErrorMessage errors={errors} name="Account_number" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="add-pay-input-main">
                    <div className="text">IFSC Code</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="IFSC Code"
                        variant="outlined"
                        {...register('Ifsc_code', { required: 'Ifsc Code is required' })}
                      />
                      <ErrorMessage errors={errors} name="Ifsc_code" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="add-pay-input-main">
                    <div className="text">Account Type</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Account Type"
                        variant="outlined"
                        {...register('Acc_type', { required: 'Account Type is required' })}
                      />
                      <ErrorMessage errors={errors} name="Acc_type" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="add-pay-input-main">
                    <div className="text">Bank Name</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Bank Name"
                        variant="outlined"
                        {...register('Bank_Name', { required: 'Bank Name is required' })}
                      />
                      <ErrorMessage errors={errors} name="Bank_Name" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="addpay-confm">
                    <Button variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >Confirm</Button>
                  </div>
                </form>
              </>
            ) : result === "2" ? (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="add-pay-input-main">
                    <div className="text">Name</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Name"
                        variant="outlined"
                        // value={name}
                        // onChange={(e) => {
                        //   setName(e.target.value);
                        // }}
                        {...register('Name', { required: 'Name is required' })}
                      />
                      <ErrorMessage errors={errors} name="Name" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>

                  <div className="add-pay-input-main">
                    <div className="text">UPI ID</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter UPI ID"
                        variant="outlined"
                        // value={upiId}
                        // onChange={(e) => {
                        //   setUpiId(e.target.value);
                        // }}
                        {...register('Upi_id', { required: 'UPI ID is required' })} // Registering the UPI ID field
                      />
                      <ErrorMessage errors={errors} name="Upi_id" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="add-pay-input-main">
                    <div className="text">UPI QR code(Optional)</div>
                    <div className="pric-vol">
                      {imageUrl && (
                        <div className="qr-image-upload">
                          <img src={imageUrl} alt="img" />
                        </div>
                      )}
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                      // startIcon={<CloudUploadIcon />}
                      >
                        Upload
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleImageUpload}
                        />
                      </Button>
                    </div>
                    <div className="addpay-confm">
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      // onClick={() => {
                      //   handleConfirm();
                      // }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="add-pay-input-main">
                    <div className="text">Name</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Name"
                        variant="outlined"
                        // value={name}
                        // onChange={(e) => {
                        //   setName(e.target.value);
                        // }}
                        {...register('Name', { required: 'Name is required' })}
                      />
                      <ErrorMessage errors={errors} name="Name" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>

                  <div className="add-pay-input-main">
                    <div className="text">GPAY ID</div>
                    <div className="pric-vol">
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter UPI ID"
                        variant="outlined"
                        // value={upiId}
                        // onChange={(e) => {
                        //   setUpiId(e.target.value);
                        // }}
                        {...register('Upi_id', { required: 'Gpay ID is required' })} // Registering the UPI ID field
                      />
                      <ErrorMessage errors={errors} name="Upi_id" render={({ message }) => <p style={{ color: 'red' }}>{message}</p>} />
                    </div>
                  </div>
                  <div className="add-pay-input-main">
                    <div className="text">GPAY QR code(Optional)</div>
                    <div className="pric-vol">
                      {imageUrl && (
                        <div className="qr-image-upload">
                          <img src={imageUrl} alt="img" />
                        </div>
                      )}
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                      // startIcon={<CloudUploadIcon />}
                      >
                        Upload
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleImageUpload}
                        />
                      </Button>
                    </div>
                    <div className="addpay-confm">
                      <Button variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >Confirm</Button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddPayment;
