import React, { useState, useEffect, useRef } from "react";
import "./Wallet.scss";
import { Grid } from "@mui/material";
import Header from "../Header/Header";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import nodata from "../../Img/thankyou.gif";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import Btc from "../../Img/btc.png";
import eth from "../../Img/eth.png";
import tht from "../../Img/theter.png";
import { useNavigate } from "react-router-dom";
import usercalls from "../../Axios";
import consts from '../../constant';
import Avatar from '@mui/material/Avatar';
import { toast } from "react-toastify";
import Backdrop from '@mui/material/Backdrop';
import loading from '../../Img/favicon.png'



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Wallet() {
  const [show, setShow] = useState(true);

  const [value, setValue] = useState(0);
  const path = usercalls()
  const [openLoad, setOpenLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [data, setData] = useState([]);

  const [loader, setLoader] = useState(true);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const navigate = useNavigate();

  const [totalAmount, setTotalAmount] = useState(0)

  const getWallets = async () => {
    try {
      const url = `${consts.BackendUrl}/getWallets`
      const response = await path.postCallTokenonly({ url });
      const result = await response.json();
      if (result.success) {
        setLoader(false)
        setData(result.result)
        setTotalAmount(result?.total_sum)
      }
      else {
        setData([])
        setLoader(false)
      }
    } catch (error) {
      console.log("🚀 ~ getWallets ~ error:", error);
    }
  }

  const mountedRef = useRef(false);

  const createWallets = async () => {
    try {
      setOpenLoad(true);
      const url = `${consts.BackendUrl}/createNewWallet`
      var token = window.localStorage.getItem('Rikosta')
      const response = await path.tokenCall({ url, payload: {}, token });
      const Walletresult = await response.json();
      if (Walletresult?.success == true) {
        getWallets()
      } else {
        toast.error(Walletresult?.message)
      }
    } catch (error) {
      console.log("🚀 ~ createWallets ~ error:", error)
    } finally {
      setOpenLoad(false);
    }
  }

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      createWallets();
    }

  }, []);

  const createDepositAddress = async (row) => {
    // try {
    // const payload = {
    //   asset: row.coinname,
    //   id: row._id
    // }
    // const url = `${consts.BackendUrl}/createDepositAddress`
    // const response = await path.postCall({ url, payload });
    // const result = await response.json();
    // if (result.success) {
    navigate("/deposit", {
      state: row?._id,
    });
    // }
    // } catch (error) {
    //   console.log("🚀 ~ depositAddress ~ error:", error);
    // }
  }

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handlewalletSearch = async (value) => {

    try {

      if (value == "") {

        const url = `${consts.BackendUrl}/getWallets`
        const response = await path.postCallTokenonly({ url });
        const result = await response.json();
        if (result.success) {
          setData(result.result)
          // setTotalAmount(result?.total_sum)
        }

      } else {

        const url = `${consts.BackendUrl}/getWallets`
        const response = await path.postCallTokenonly({ url });
        const result = await response.json();
        if (result.success) {
          const searchedWallet = result?.result.filter( item => item.coinname.includes(value.toUpperCase()));
          setData(searchedWallet)
          // setTotalAmount(result?.total_sum)
        }
        else {
          setData([])
        }

      }

    } catch (error) {
      console.log(error, "error in searching wallets",);
    }

  };

  const debouncedHandlewalletSearch = debounce(handlewalletSearch, 500);

  return (
    <div className="Wallet">
      <Grid container sx={{
        display: {
          xs: 'block', // Mobile (xs: extra-small devices)
          sm: 'flex',  // Default for larger screens (optional)
        },
      }}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={10} className="contain-width">
          <div className="wallte-div-1">
            <div className="display-1 wallet-min2" >
              <div className="last-update">
                <div className="show-value display-2 gap">
                  <p>Total Assets</p>
                  {!show ? (
                    <VisibilityOffIcon
                      onClick={() => {
                        setShow(!show);
                      }}
                      className="cursor"
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => {
                        setShow(!show);
                      }}
                      className="cursor"
                    />
                  )}
                </div>
                <div className="wallet-balance display-2 gap">
                  {show ? totalAmount : "*****"} USDT
                </div>
              </div>
              <div className="show-value">
                <p>Total Deposit</p>
                <div className="display-2">
                  <span>0$</span>
                  <FileDownloadOutlinedIcon className="green" />
                </div>
              </div>

              <div className="show-value">
                <p>Total Withdraw</p>
                <div className="display-2">
                  <span>0$</span>
                  <FileUploadOutlinedIcon className="red" />
                </div>
              </div>
            </div>
          </div>
          <div className="wallte-div-1">
            <div className="display-1 wallet-min2" style={{ marginBottom: "10px" }}>
              <h2 style={{ color: "var(--clr-font)" }}>Wallet Balance</h2>
              <div className="search-input">
                <TextField
                  id="outlined-basic"
                  placeholder="Search"
                  // onChange={(e) => handlewalletSearch(e?.target?.value)}
                  onChange={(e) => debouncedHandlewalletSearch(e?.target?.value)}
                  variant="outlined"
                />
              </div>
            </div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ padding: "0px" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Crypto" {...a11yProps(0)} />
                  {/* <Tab label="Fiat" {...a11yProps(1)} /> */}
                  {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0} className="wallet-his">

                <Box sx={{ width: '100%', overflowX: 'auto' }}>
                  <TableContainer>
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Coin </TableCell>
                          <TableCell align="right">Available Balance</TableCell>
                          <TableCell align="right">Escrow Balance</TableCell>
                          <TableCell align="right">Total Balance</TableCell>
                          <TableCell align="right">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.length > 0 ? (
                          loader ? (
                            <TableRow>
                              <TableCell colSpan={5} align="center">
                                <CircularProgress color="inherit" />
                              </TableCell>
                            </TableRow>
                          ) : (
                            <>
                              {data && [...data].slice((page - 1) * perPage, page * perPage)
                                .map((row, ind) => {
                                  return (
                                    <TableRow>
                                      <TableCell align="left">
                                        <div className="display-1 gap">
                                          <div className="table-logo">
                                            {
                                              !row?.asset_id?.imageurl ? <Avatar sx={{ width: 25, height: 25 }}>{row?.coinname?.split("")[0]}</Avatar> :
                                                <img src={row?.asset_id?.imageurl} alt="img" />
                                            }
                                            {row?.coinname}
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell align="right">
                                        {Number(row?.balance)?.toFixed(5)}
                                      </TableCell>
                                      <TableCell align="right">
                                        {Number(row?.escrow_balance)?.toFixed(5)}
                                      </TableCell>
                                      <TableCell align="right">
                                        {(Number(row?.balance) + Number(row?.escrow_balance))?.toFixed(5)}
                                      </TableCell>
                                      <TableCell align="right">
                                        <div className="display-4 gap depo-with">
                                          <Button
                                            onClick={() => {
                                              createDepositAddress(row);
                                            }}
                                            variant="contained"
                                            className="bg-green"
                                          >
                                            Deposit
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              navigate("/withdraw", { state: { data: row?._id, amount: Number(row?.balance)?.toFixed(5) } });
                                            }}
                                            variant="contained"
                                            className="bg-red"
                                          >
                                            Withdraw
                                          </Button>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </>
                          )
                        ) : (
                          <>
                            <TableRow>
                              <TableCell align="center" colSpan={5}>
                                <div className="nodata">
                                  <img src={nodata} alt="nodata" />
                                  No Record Found
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {data && (
                  <Pagination
                    className="pagnation display-3"
                    count={Math.ceil(data?.length / perPage)}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                  />
                )}
              </CustomTabPanel>

              {/* <CustomTabPanel value={value} index={1} className="wallet-his">
                <TableContainer>
                  <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Action </TableCell>
                        <TableCell align="left">Amount </TableCell>
                        <TableCell align="left">Date/Time</TableCell>
                        <TableCell align="left">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.length > 0 ? (
                        loader ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <CircularProgress color="inherit" />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {data
                              .slice((page - 1) * 10, page * 10)
                              .map((row, ind) => {
                                return (
                                  <TableRow>
                                    <TableCell align="left">
                                      {(page - 1) * 10 + ind + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.name}
                                    </TableCell>

                                    <TableCell align="left">
                                      {row.date}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.kyc}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </>
                        )
                      ) : (
                        <>
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <div className="nodata">
                                <img src={nodata} alt="nodata" />
                                No Record Found
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {data && (
                  <Pagination
                    className="pagnation display-3"
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                    shape="rounded"
                  />
                )}
              </CustomTabPanel> */}
            </Box>
          </div>
        </Grid>
      </Grid>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backdropFilter: 'blur(15px)' })}
        open={openLoad}

      >
        <div className="display-1" style={{ flexDirection: 'column' }} >
          <div class="loading-bar">
            <img src={loading} alt="loading" width="80px" height="80px" style={{ marginTop: '15px' }} />
          </div>
          <p style={{ display: 'inline', fontSize: '16px', color: '#f66754' }}> Hang tight! This operation may take few time. </p>
        </div>


      </Backdrop>

    </div>
  );
}

export default Wallet;
