import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./Login.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import lightlogo from "../../Img/light-mode-logo.png";
import usercalls from "../../Axios";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

export default function Forgot() {

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const navigate = useNavigate();
    const path = usercalls()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()

    const [otp, setOtp] = useState()

    const [load, setLoad] = useState(false)
    const [status, setStatus] = useState(false)
    const [forgot, setForgot] = useState(true)

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleClickShowPassword1 = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get("email"),
    //         password: data.get("password"),
    //     });
    // };



    const handleSubmit = async () => {
        try {
            setLoad(true)
            const url = `${consts.BackendUrl}/forgot`
            const payload = {
                email: email,
            }
            const response = await path.postCallWithoutAuthToken({ url, payload });
            const result = await response.json();
            if (result.success) {
                toast.success(result.message)
                setLoad(false)
                setForgot(false)
                setStatus(true)
            }
            else {
                setLoad(false)
                setStatus(false)
                toast.error(result.message)
            }
        } catch (error) {
            setStatus(false)
            console.log("🚀 ~ forgotpass ~ error:", error);
        }
    };

    const handleOTPSubmit = async () => {
        try {
            if (password && !passwordRegex.test(password)) {
                toast.error("Password Must Contain MIN 8 Character , One ALPHABET And Must Contain One Number and Special Characters  Eg : Abc@1234")
            }
            else if (email && password && confirmPassword == undefined) {
                toast.error('Please Confirm Password')
            }
            else if (confirmPassword !== password) {
                toast.error('Confirm Password MisMatch')
            }
            else {
                setLoad(true)
                const url = `${consts.BackendUrl}/reset`
                const payload = {
                    otp: otp,
                    email: email,
                    password: password

                }
                const response = await path.postCallWithoutAuthToken({ url, payload });
                const result = await response.json();
                if (result.success) {
                    toast.success(result.message)
                    setTimeout(() => {
                        setLoad(false)
                        navigate('/')
                    }, 2000)
                }
                else {
                    setLoad(false)
                    toast.error(result.message)
                }
            }

        } catch (error) {
            setStatus(false)
            console.log("🚀 ~ resetpass ~ error:", error);
        }
    };



    return (
        <>
            <ToastContainer />
            <div className="Login">
                <div className="logo">
                    <Link to="/">
                        {" "}
                        <img src={lightlogo} />
                    </Link>
                </div>
                <Grid
                    container
                    component="main"
                    sx={{ justifyContent: "center" }}
                >
                    <Grid item xs={11} sm={8} md={7} lg={7} xl={6}>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} sm={10} md={9} lg={6} xl={6}>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                        padding: "25px",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography component="h1" variant="h5" className="text-center c-accout" sx={{ mt: 1, mb: 3 }}>
                                        {forgot ? 'Forgot Password' : 'Reset Password'}
                                    </Typography>
                                    <Box
                                        // component="form"
                                        // noValidate
                                        // onSubmit={handleSubmit}
                                        sx={{ mt: 1 }}
                                    >
                                        <div className="email-fill">
                                            <div className="email-name">
                                                Email
                                            </div>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                // label="Email Address"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </div>
                                        {
                                            status &&
                                            <>
                                                <div className="email-fill">
                                                    <div className="email-name">
                                                        OTP
                                                    </div>
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        id="otp"
                                                        // label="Email Address"
                                                        name="otp"
                                                        autoComplete="otp"
                                                        autoFocus
                                                        value={otp}
                                                        onChange={(e) => {
                                                            if (/^\d{0,6}$/.test(e.target.value)) {
                                                                setOtp(e.target.value)
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="email-fill mt-10">
                                                    <div className="email-name">
                                                        New Password
                                                    </div>
                                                    <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={
                                                                showPassword ? 'hide the password' : 'display the password'
                                                            }
                                                            onClick={handleClickShowPassword1}

                                                            edge="end"
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                            // label="Password"
                                                            
                                                            value={password}
                                                            onChange={(e) => { setPassword(e.target.value) }}
                                            />
                                                   </FormControl>
                                                </div>

                                                <div className="email-fill mt-10">
                                                    <div className="email-name">
                                                        Confirm Password
                                                    </div>
                                                    <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword2 ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={
                                                                showPassword2 ? 'hide the password' : 'display the password'
                                                            }
                                                            onClick={handleClickShowPassword2}

                                                            edge="end"
                                                        >
                                                            {!showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                            // label="Password"
                                                            
                                                            value={confirmPassword}
                                                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                                            />
                                                   </FormControl>
                                                   
                                                </div>
                                            </>

                                        }



                                        <div className="login-btn">
                                            {
                                                load ?
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Processing...
                                                    </Button> :
                                                    forgot ?
                                                        <Button
                                                            type="submit"
                                                            fullWidth
                                                            sx={{ mt: 3, mb: 2 }}
                                                            onClick={() => { handleSubmit() }}
                                                        >
                                                            send
                                                        </Button> :
                                                        <Button
                                                            type="submit"
                                                            fullWidth
                                                            sx={{ mt: 3, mb: 2 }}
                                                            onClick={() => { handleOTPSubmit() }}
                                                        >
                                                            Submit
                                                        </Button>
                                            }

                                        </div>


                                        <div className="text-center accnt-sign mt-10">
                                            <Link to="/register" variant="body2">
                                                Don't have an account? <span>Sign Up</span>
                                            </Link>
                                        </div>
                                    </Box>
                                </Box>



                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
