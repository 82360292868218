import { Button, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'

// import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import nodata from '../../Img/thankyou.gif'
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { useTheme, useMediaQuery } from '@mui/material';
import usercalls from "../../Axios";

import AppBar from '@mui/material/AppBar';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Toolbar from '@mui/material/Toolbar';

import Drawer from '@mui/material/Drawer';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import SpTab from './SpTab';
import Transfer from './Transfer';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',  // Start from the left side of the screen
    transform: 'translate(-50%, -50%)', // Center vertically
    "@media (max-width: 767.98px)": {
        width: '80%',
        // left: '18%'
    },
    width: 320,
    bgcolor: 'var(--box-bg)',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '12px',
    pt: 2,
    px: 4,
    pb: 3,
};


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function PlaceOrderMargin({ prices, setHistoryStatus }) {

    const loaction = useLocation();
    const placeRoute = loaction.pathname?.split('/')[1];
    const navigate = useNavigate()
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobilemd = useMediaQuery(theme.breakpoints.up('md'));
    const [baseMargin, setBaseMargin] = useState(0);
    const [quoteMargin, setQuoteMargin] = useState(0);

    const [baseBorrow, setBaseBorrow] = useState(0)
    const [quoteBorrow, setQuoteBorrow] = useState(0)


    const [Borrowing_buy, setBorrowing_buy] = useState(0)
    const [repay_buy, setRepay_buy] = useState(0)
    const [borrow, setBorow] = useState(0);
    const [isBorrow, setIsBorrow] = useState("Normal");

    const [marginCont, setMarginCont] = useState([
        { name: 'Normal' },
        { name: 'Borrow' },
        { name: 'Repay' },
        // { name: 'Auto-Borrow & Reply' },
    ])
    const [marginIndex, setMarginIndex] = useState()
    const handleMarginCont = (val) => {
        setMarginIndex(val);
        setIsBorrow(marginCont[val].name);
    }

    const [value2, setValue2] = useState(0);

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const { id } = useParams()

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };


    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };


    const [cross, setCross] = useState('CROSSED')

    const [crossVal, setCrossVal] = useState('Cross')

    const [xVal, setXVal] = useState(3)
    const [xVal2, setXVal2] = useState(`20`)

    const [xValSet, setXValSet] = useState(xVal)
    const path = usercalls()
    const [load, setLoad] = useState(false);
    const [value3, setValue3] = useState(0);

    const handleChange3 = (event, newValue) => {
        setValue3(newValue);
        setOrderPrice("")
        setQuantity("")
        setBorrowing_buy(0)
        setRepay_buy(0)
    };


    const [value4, setValue4] = useState(0);

    const [side, setSide] = useState("LIMIT")

    const handleChange4 = (event, newValue) => {
        var sides = ["LIMIT", "MARKET"]
        setSide(sides[newValue])
        setValue4(newValue);
        setBorrowing_buy(0)
        setQuantity(0)
        setOrderPrice(0)
    };

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open, val) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setValue3(val)

        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [orderPrice, setOrderPrice] = useState()
    const [quantity, setQuantity] = useState()

    const [balanceBuy, setBalanceBuy] = useState(0)
    const [balanceSell, setBalanceSell] = useState(0)

    const getBalance = async () => {
        try {
            const url = `${consts.BackendUrl}/wallet/getWallet`
            const payload = {
                ccy: id
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                setBalanceBuy(result?.result?.quoteCoin?.marginBalance)
                setBalanceSell(result?.result?.baseCoin?.marginBalance)
            }
        } catch (error) {
            console.log("🚀 ~ getBalance ~ error:", error)
        }
    }

    useEffect(() => {
        if (id) {
            getBalance()
        }
    }, [id])

    useEffect(() => {
        if (prices?.length > 0) {
            setOrderPrice(Number(prices[0])?.toFixed(4))
            setQuantity(Number(prices[1])?.toFixed(4))
        }
    }, [prices])

    const placeOrderTrade = async (type) => {
        try {
            if (side == 'LIMIT' && (!orderPrice || !quantity)) {
                toast.error("Please Enter Price and Quantity")
            }
            else if (!quantity) {
                toast.error("Please Enter Quantity")
            }
            // else if (type == "BUY" && balanceBuy == 0) {
            //     toast.error("Insufficient Balance")
            // } else if (type == "SELL" && balanceSell == 0) {
            //     toast.error("Insufficient Balance")
            // }
            else {

                var payload;
                var url;

                if (placeRoute == "trade") {
                    url = `${consts.BackendUrl}/binanceTrade/trade`
                    payload = {
                        ccy: id?.split("_")?.join(""),
                        type: side,
                        price: orderPrice,
                        quantity: quantity,
                        side: type,
                    }
                } else {
                    url = `${consts.BackendUrl}/binanceTrade/margin/margintrade`
                    payload = {
                        ccy: id,
                        side: type,
                        type: side,
                        price: orderPrice,
                        quantity: quantity,
                        isIsolated: cross == "CROSSED" ? false : true,
                        tradetype: isBorrow
                    }

                    console.log(payload, "payload");
                }


                const response = await path.postCall({ url, payload });
                const result = await response.json();
                if (result?.success) {
                    toast.success(result?.message)
                    setQuantity("")
                    setOrderPrice("")
                    getBalance()
                    setHistoryStatus(Math.floor(Math.random() * 90) + 10)
                } else {
                    toast.error(result?.message)
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            console.log("🚀 ~ placeOrderTrade ~ error:", error)
        }
    }

    const calculateTradeQuantity = async (balance, percentage, price) => {
        if (side == "LIMIT") {
            if (price != "") {
                if (value3 == 0) {
                    const decimalPercentage = percentage / 100;
                    const quantity = Number((balance * decimalPercentage) / price).toFixed(5)
                    setQuantity(quantity);
                    if (quantity > baseMargin) {
                        setBorow(quantity);
                    }
                    if ((orderPrice * quantity) > balanceBuy) {
                        setBorrowing_buy((orderPrice * quantity) - balanceBuy)
                    } else {
                        setBorrowing_buy(0)
                    }
                    console.log(quoteBorrow, orderPrice * quantity, "baseBorrow")
                    if ((orderPrice * quantity) > quoteBorrow) {
                        setRepay_buy(quoteBorrow)
                    } else {
                        setRepay_buy((orderPrice * quantity))
                    }
                } else {
                    const decimalPercentage = percentage / 100;
                    const quantity = Number((balance * decimalPercentage)).toFixed(5)
                    setQuantity(quantity);
                    if (quantity > baseMargin) {
                        setBorow(quantity);
                    }
                    if ((quantity) > balanceSell) {
                        setBorrowing_buy((quantity) - balanceSell)
                    } else {
                        setBorrowing_buy(0)
                    }
                    if ((orderPrice * quantity) > baseBorrow) {
                        setRepay_buy(baseBorrow)
                    } else {
                        setRepay_buy((orderPrice * quantity))
                    }
                }
            }
        } else {
            if (value3 == 0) {
                const decimalPercentage = percentage / 100;
                const quantity = Number((balance * decimalPercentage)).toFixed(5)
                setQuantity(quantity);
                if (quantity > baseMargin) {
                    setBorow(quantity);
                }
                if ((quantity) > balanceBuy) {
                    setBorrowing_buy((quantity) - balanceBuy)
                } else {
                    setBorrowing_buy(0)
                }
            } else {
                const decimalPercentage = percentage / 100;
                const quantity = Number((balance * decimalPercentage)).toFixed(5)
                setQuantity(quantity);
                if (quantity > baseMargin) {
                    setBorow(quantity);
                }
                if ((quantity) > balanceSell) {
                    setBorrowing_buy((quantity) - balanceSell)
                } else {
                    setBorrowing_buy(0)
                }
            }
        }
    };


    useEffect(() => {
        console.log(quantity, "quantity")
        if (side == "LIMIT") {
            if (value3 == 0) {
                if ((orderPrice * quantity) > balanceBuy) {
                    setBorrowing_buy((orderPrice * quantity) - balanceBuy)
                } else {
                    setBorrowing_buy(0)
                }

                if ((orderPrice * quantity) > quoteBorrow) {
                    setRepay_buy(quoteBorrow)
                } else {
                    setRepay_buy((orderPrice * quantity))
                }
            } else {
                console.log(quantity, balanceSell, "balanceBuy")
                if ((quantity) > balanceSell) {
                    setBorrowing_buy((quantity) - balanceSell)
                } else {
                    setBorrowing_buy(0)
                }

                if ((orderPrice * quantity) > baseBorrow) {
                    setRepay_buy(baseBorrow)
                } else {
                    setRepay_buy((orderPrice * quantity))
                }
            }
        } else {
            if (value3 == 0) {
                if ((quantity) > balanceBuy) {
                    setBorrowing_buy((quantity) - balanceBuy)
                } else {
                    setBorrowing_buy(0)
                }

                if ((quantity) > quoteBorrow) {
                    setRepay_buy(quoteBorrow)
                } else {
                    setRepay_buy((quantity))
                }
            } else {
                console.log(quantity, balanceSell, "balanceBuy")
                if ((quantity) > balanceSell) {
                    setBorrowing_buy((quantity) - balanceSell)
                } else {
                    setBorrowing_buy(0)
                }

                if ((quantity) > baseBorrow) {
                    setRepay_buy(baseBorrow)
                } else {
                    setRepay_buy((quantity))
                }
            }
        }

    }, [orderPrice, quantity])

    const calculateSellTradeQuantity = async (balance, percentage, price) => {
        if (price !== "") {
            const decimalPercentage = percentage / 100;
            const quantity = Math.round((balance * decimalPercentage) / price);
            setQuantity(quantity);
        }
    };

    const calculateTradeDetails = async (balance, percentage, price, leverage = 1) => {
        if (price != "") {
            const decimalPercentage = percentage / 100;

            // Calculate trade quantity (rounded down to the nearest integer)
            const quantity = Math.floor((balance * decimalPercentage) / price);

            // Calculate total limit with leverage
            const totalLimit = balance * leverage;

            // Calculate trade amount (price × quantity)
            const tradeAmount = price * quantity;

            // Calculate borrow amount (if trade amount exceeds balance)
            const borrowAmount = tradeAmount > balance ? tradeAmount - balance : 0;

            // Example: Return or use the values
            return {
                quantity,
                totalLimit,
                borrowAmount,
            };
        }
    };


    const handleMarginLeverage = async () => {
        setLoad(true);
        try {

            const url = `${consts.BackendUrl}/binanceTrade/margin/changeleverage`
            const payload = {
                leverage: xVal
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                toast.success("Leverage Changed Successfully")
            } else {
                if (result?.message == "Too many requests; current request has limited.") {
                    toast.error("Operation Performed Too Frequently.")
                } else {
                    toast.error(result?.message)
                }
            }

        } catch (error) {
            console.log(error, "error changing the margin leverage");
        } finally {
            setOpen2(false);
            setLoad(false);
        }
        getMarginDetails();
    };

    const handleleveragechange = async (val) => {

        try {

            if (val == 0) {
                setXVal(3);
            } else if (val == 5) {
                setXVal(5);
            } else {
                setXVal(10);
            }

        } catch (error) {
            console.log(error, "error changing leverage");
        }

    };

    const getMarginDetails = async () => {

        try {

            const url = `${consts.BackendUrl}/binanceTrade/margin/getmargindetailes`
            const payload = {
                ccy: id
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {

                for (var i = 0; i < result?.result.length; i++) {

                    if (result?.result[i].asset == id.split("_")[0]) {
                        setQuoteMargin(result?.result[i]?.amount);
                        setQuoteBorrow(result?.result[i]?.borrowed)
                    } else if (result?.result[i].asset == id.split("_")[1]) {
                        setBaseMargin(result?.result[i]?.amount);
                        setBaseBorrow(result?.result[i]?.borrowed)
                    }

                }

                // setQuoteMargin(result?.result[0]?.borrowableData?.amount);
                // setBaseMargin(result?.result[1]?.borrowableData?.amount);

            }

        } catch (error) {
            console.log(error, "error getting margin details");
        }

    };

    useEffect(() => {
        getMarginDetails();
    }, [id]);

    const placeOrder = (
        <>
            <Box  >
                {!mobilemd && <div className="text-right">
                    <HighlightOffIcon sx={{ fill: 'var(--clr-font)', cursor: 'pointer' }} onClick={(() => { setState({ bottom: false }) })} />
                </div>}
                <div className='display-2'>
                    <Tabs value={value2} onChange={handleChange2} aria-label="basic tabs example" className=' mob reUp'>
                        <Tab label="Place Order" {...a11yProps(0)} />
                        {/* <Tab label=""  /> */}
                    </Tabs>
                    <Transfer />
                </div>

            </Box>
            <CustomTabPanel value={value2} index={0} className='spot-tab mt-10'>
                <Grid container >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SpTab />

                        <div className="display-1 mt-10" style={{ gap: "5px" }}>
                            <div className='cross'>
                                <div onClick={handleOpen}>
                                    {crossVal}
                                </div>
                            </div>
                            <div className='x50' onClick={handleOpen2}>
                                <div>
                                    {xValSet}X
                                </div>
                            </div>
                        </div>

                        <Box sx={{ width: '100%' }} className='mt-10'>
                            {mobilemd && <Box>
                                <Tabs value={value3} onChange={handleChange3} aria-label="basic tabs example" className='buy-sell-tabs'>
                                    <Tab label="Buy" {...a11yProps(0)} className='buy' />
                                    <Tab label="Sell" {...a11yProps(1)} className='sell' />

                                </Tabs>
                            </Box>}
                            <CustomTabPanel value={value3} index={0}>
                                <Grid container>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ width: '100%' }} className="mt-10">
                                            <Box>
                                                <Tabs value={value4} onChange={handleChange4} aria-label="basic tabs example" className='tabs-trade'>
                                                    <Tab label="Limit" {...a11yProps(0)} />
                                                    <Tab label="Market" {...a11yProps(1)} />
                                                    {/* <Tab label="Advanced Limit" {...a11yProps(2)} /> */}
                                                </Tabs>
                                            </Box>
                                            <CustomTabPanel value={value4} index={0}>
                                                <div className='display-1 mt-10 mb-10' style={{ overflowX: 'visible' }}>
                                                    {marginCont.map((row, i) => {
                                                        return (
                                                            <div className={marginIndex === i ? "ft-btn active" : "ft-btn"} onClick={(() => { handleMarginCont(i) })}>
                                                                <Button variant='contained'>
                                                                    {row.name}
                                                                </Button>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2 mt-10">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                placeholder='Order Price'
                                                                value={orderPrice}
                                                                onChange={(e) => {
                                                                    setOrderPrice(e.target.value)
                                                                }}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => {
                                                                    setQuantity(e.target.value)
                                                                }}
                                                                placeholder='Quantity'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={(orderPrice * quantity).toFixed(5)}
                                                                placeholder='Order Value'
                                                                type="number"
                                                            />
                                                        </FormControl>


                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 25, orderPrice) }}>25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 50, orderPrice) }} >50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 75, orderPrice) }} >75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 100, orderPrice) }} >100%</span>
                                                                </label>

                                                            </div>
                                                        </div>
                                                        {/* <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-weight"
                                                                            endAdornment={<InputAdornment position="end" className='adroment'>USDT</InputAdornment>}
                                                                            aria-describedby="outlined-weight-helper-text"
                                                                            inputProps={{
                                                                                'aria-label': 'weight',
                                                                            }}
                                                                            placeholder='Volume'
                                                                            type="number"
                                                                        />
                                                                    </FormControl> */}

                                                        {/* <div className="display-2 mt-20">
                                                                        <div className="aval">
                                                                            Advanced:
                                                                        </div>
                                                                        <div className="display-1 usd">

                                                                            <div className="">
                                                                                Settings
                                                                            </div>


                                                                        </div>

                                                                    </div> */}

                                                        <div className="buy-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("BUY") }}  >Buy</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }

                                                        </div>


                                                        {marginIndex === 1 ? <div className="display-2 mt-20">
                                                            {/* <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {borrow} {id?.split("_")[1]}
                                                                </div>
                                                            </div> */}
                                                            <div className="aval">
                                                                Max
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(baseMargin).toFixed(4)} {id?.split("_")[1]}
                                                                </div>
                                                            </div>

                                                            <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(Borrowing_buy).toFixed(4)} {id?.split("_")[1]}
                                                                </div>
                                                            </div>


                                                        </div> : marginIndex === 2 ?
                                                            <>
                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {repay_buy ? repay_buy : 0} {id?.split("_")[0]}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </>
                                                            : marginIndex === 3 ? <>
                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Borrowing
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>}
                                                    </Grid>

                                                </Grid>
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value4} index={1}>
                                                <div className='display-1' style={{ overflowX: 'scroll' }}>
                                                    {marginCont.map((row, i) => {
                                                        return (
                                                            <div className={marginIndex === i ? "ft-btn active" : "ft-btn"} onClick={(() => { handleMarginCont(i) })}>
                                                                <Button variant='contained'>
                                                                    {row.name}
                                                                </Button>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                // endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                placeholder='Best Market Price'

                                                                disabled
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => { setQuantity(e.target.value) }}
                                                                placeholder='Amount'
                                                                type="number"
                                                            />
                                                        </FormControl>


                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 25, orderPrice) }}>25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 50, orderPrice) }} >50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 75, orderPrice) }} >75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? baseMargin : balanceBuy, 100, orderPrice) }} >100%</span>
                                                                </label>

                                                            </div>
                                                        </div>
                                                        <div className="buy-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("BUY") }}  >Buy</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }
                                                        </div>

                                                        {marginIndex === 1 ? <div className="display-2 mt-20">
                                                            {/* <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div> */}
                                                            <div className="aval">
                                                                Max
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(baseMargin).toFixed(4)} {id?.split("_")[1]}
                                                                </div>
                                                            </div>

                                                            <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(Borrowing_buy).toFixed(4)} {id?.split("_")[1]}
                                                                </div>
                                                            </div>
                                                        </div> : marginIndex === 2 ?
                                                            <>
                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            Based on Trade Price
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </>
                                                            : marginIndex === 3 ? <>
                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Borrowing
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>}
                                                    </Grid>

                                                </Grid>
                                            </CustomTabPanel>

                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={value3} index={1}>
                                <Grid container>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ width: '100%' }} className='mt-10'>
                                            <Box>
                                                <Tabs value={value4} onChange={handleChange4} aria-label="basic tabs example" className='tabs-trade'>
                                                    <Tab label="Limit" {...a11yProps(0)} />
                                                    <Tab label="Market" {...a11yProps(1)} />
                                                    {/* <Tab label="Advanced Limit" {...a11yProps(2)} /> */}
                                                </Tabs>
                                            </Box>
                                            <CustomTabPanel value={value4} index={0}>
                                                <div className='display-1 mt-10' style={{ overflowX: 'scroll' }}>
                                                    {marginCont.map((row, i) => {
                                                        return (
                                                            <div className={marginIndex === i ? "ft-btn active" : "ft-btn"} onClick={(() => { handleMarginCont(i) })}>
                                                                <Button variant='contained'>
                                                                    {row.name}
                                                                </Button>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2 mt-10">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceSell} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={orderPrice}
                                                                onChange={(e) => {
                                                                    setOrderPrice(e.target.value)
                                                                }}
                                                                placeholder='Order Price'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => {
                                                                    setQuantity(e.target.value)
                                                                }}
                                                                placeholder='Quantity'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={orderPrice * quantity}
                                                                placeholder='Order Value'
                                                                type="number"
                                                            />
                                                        </FormControl>


                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 25, orderPrice) }}  >25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 50, orderPrice) }}  >50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 75, orderPrice) }}  >75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 100, orderPrice) }}  >100%</span>
                                                                </label>

                                                            </div>
                                                        </div>

                                                        <div className="sell-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("SELL") }}  >Sell</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }
                                                        </div>

                                                        {marginIndex === 1 ? <div className="display-2 mt-20">
                                                            {/* <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div> */}
                                                            <div className="aval">
                                                                Max
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(quoteMargin).toFixed(4)} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                            <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(Borrowing_buy).toFixed(4)} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                        </div> : marginIndex === 2 ?
                                                            <>
                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {repay_buy ? repay_buy : 0} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </>
                                                            : marginIndex === 3 ? <>
                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Borrowing
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>}
                                                        {/* <div className="display-2 mt-20">
                                                                        <div className="aval">
                                                                            Advanced:
                                                                        </div>
                                                                        <div className="display-1 usd">

                                                                            <div className="">
                                                                                Settings
                                                                            </div>


                                                                        </div>
                                                                    </div> */}


                                                    </Grid>

                                                </Grid>
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value4} index={1}>
                                                <div className='display-1' style={{ overflowX: 'scroll' }}>
                                                    {marginCont.map((row, i) => {
                                                        return (
                                                            <div className={marginIndex === i ? "ft-btn active" : "ft-btn"} onClick={(() => { handleMarginCont(i) })}>
                                                                <Button variant='contained'>
                                                                    {row.name}
                                                                </Button>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceSell} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                // endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                placeholder='Best Market Price'
                                                                // type="number"
                                                                disabled
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => { setQuantity(e.target.value) }}
                                                                placeholder='Amount'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 25, orderPrice) }} >25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 50, orderPrice) }} >50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 75, orderPrice) }} >75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { calculateTradeQuantity(isBorrow == "Borrow" ? quoteMargin : balanceSell, 100, orderPrice) }} >100%</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="sell-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("SELL") }}  >Sell</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }
                                                        </div>

                                                        {marginIndex === 1 ? <div className="display-2 mt-20">
                                                            {/* <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div> */}
                                                            <div className="aval">
                                                                Max
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(quoteMargin).toFixed(4)} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                            <div className="aval">
                                                                Borrowing
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {(Borrowing_buy).toFixed(4)} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                        </div> : marginIndex === 2 ?
                                                            <>
                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            Based on Trade Price
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </>
                                                            : marginIndex === 3 ? <>
                                                                {/* <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Borrowing
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Repay
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="display-2 mt-10">
                                                                    <div className="aval">
                                                                        Liabilities
                                                                    </div>
                                                                    <div className="display-1 usd">
                                                                        <div className="">
                                                                            {balanceBuy} {id?.split("_")[1]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>}
                                                    </Grid>
                                                </Grid>
                                            </CustomTabPanel>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>

                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                    </Grid>
                </Grid>
            </CustomTabPanel>

        </>
    )
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, padding: !mobilemd ? '15px' : '' }}
            role="presentation"
        >
            {placeOrder}
        </Box>
    );

    return (
        <div className='trade-left'>
            <ToastContainer />
            <Grid container >
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={!mobilemd && { display: 'none' }}>
                        {placeOrder}
                    </Box>

                </Grid>
            </Grid >

            {!mobilemd && <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, }}>
                <Toolbar sx={{ justifyContent: 'space-evenly', }}>

                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className="buy-h-btn display-2" style={{ width: '100%' }}>
                                <Button variant='contained' onClick={toggleDrawer(anchor, true, 0)}>{'Buy'}</Button>
                            </div>

                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                // onClose={toggleDrawer(anchor, false)}
                                className='drawer-bottom'
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}


                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className="sell-h-btn display-2" style={{ width: '100%' }}>

                                <Button variant='contained' onClick={toggleDrawer(anchor, true, 1)}>{'Sell'}</Button>
                            </div>

                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                // onClose={toggleDrawer(anchor, false)}
                                className='drawer-bottom'
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}

                </Toolbar>
            </AppBar>}

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="text-right">
                        <HighlightOffIcon onClick={handleClose} className='cursor' sx={{ fill: 'var(--clr-font)' }} />
                    </div>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='font-clr'>
                        Margin Mode
                    </Typography>
                    <div className="mt-20 font-clr">
                        {/* BTCUSDT */}
                        {id?.split("_")?.join("/")}
                    </div>
                    <div className="mt-20" >
                        <div id="firstFilter" class="filter-switch">
                            <input id="option1" name="options" type="radio" value="CROSSED" onChange={((e) => { setCross(e.target.value) })} defaultChecked={cross === 'Cross' && true} />
                            <label class="option" for="option1">Cross</label>
                            <input id="option2" name="options" type="radio" value="ISOLATED" onChange={((e) => { setCross(e.target.value) })} defaultChecked={cross === 'Isolated' && true} />
                            <label class="option" for="option2">Isolated</label>
                            <span class="background"></span>
                        </div>

                    </div>
                    <div className="cnfi mt-50">
                        <Button onClick={handleClose}>
                            Confirm
                        </Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={open2}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="text-right">
                        <HighlightOffIcon onClick={handleClose2} className='cursor' sx={{ fill: 'var(--clr-font)' }} />
                    </div>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='font-clr'>
                        Leverage Mode
                    </Typography>
                    <div className="mt-20 font-clr">
                        {/* BTCUSDT */}
                        {id?.split("_")?.join("/")}
                    </div>
                    <div className="mt-20 font-clr display-3">
                        {/* <div className="cursor">
                            <RemoveCircleOutlineIcon sx={{ fill: 'red', fontSize: '30px !important' }} onClick={(() => { setXVal(xVal - 1) })} />
                        </div> */}

                        <div className="">
                            <div className="">{xVal === 0 ? 3 : xVal === 5 ? 5 : 10}X</div>
                        </div>

                        {/* <div className="cursor">
                            <AddCircleOutlineIcon sx={{ fill: 'green', fontSize: '30px !important' }} onClick={(() => { setXVal(xVal + 1) })} />
                        </div> */}
                    </div>
                    <div className="mt-20 sld">
                        {/* <Slider defaultValue={xVal} min={0} mid={5} max={10} step={5} aria-label="Default" valueLabelDisplay="auto" onChange={((e) => { setXVal(e.target.value) })} /> */}
                        <Slider defaultValue={xVal} min={0} mid={5} max={10} step={5} aria-label="Default" valueLabelDisplay="auto" onChange={((e) => { handleleveragechange(e.target.value) })} />
                        <div className="mt-10 display-2">
                            <div className="">3X</div>
                            <div className="">5X</div>
                            <div className="">10X</div>
                        </div>
                    </div>

                    <div className="cnfi mt-50">
                        <Button disabled={load} onClick={() => handleMarginLeverage()}>
                            Confirm
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

export default PlaceOrderMargin
